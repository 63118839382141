.userList {
    width: 100%;
    height: auto; 
  }

  .userAddButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
  }

  .backButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
  }

  .link-create {
      margin: 0px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 80px;
  }
  
  .nameListUser {
    display: flex;
    align-items: center;
  }
  
  .nameListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .userListEdit{
      border: none;
      border-radius: 10px;
      background-color: #3bb077;
      color: white;
      cursor: pointer;
      margin-right: 20px;
      width: 50px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .delete-edit-button {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }

  .edit-button {
    width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-left: 43px;
  }
  
  .userListDelete{
      color: red;
      cursor: pointer;
  }

  .sidebar {
      /* flex: 1; */
      width: auto;
      /* border: solid 3px yellow; */
  }

  .backofficeNav {
      width: auto;
      margin: 10px 0 10px 0;
      position: sticky;
      top: 0;
      z-index: 999;
  }

  .backofficeUserList {
      width: 100%;
      height: auto;
  }

  .sidebar-userlist {
      height: 100vh;
      display: flex;
      flex-direction: row;
  }

  .inputNone {
    display: none;
  }

  .selectSections {
    width: 90%;
  }

  .selectSections select {
    width: 100%;
  }

  .selectSite {
    width: 100%;
  }

  .selectSite select {
    width: 100%;
  }

  .check {
    width: 100%;
    /* display: flex;
    justify-content: center; */
  }

  /* .cancel {
    width: 100%;
    display: flex;
    justify-content: center;
    color: red;
  } */

  .datagridContainer {
    height: auto;
  }

  .checkContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkOk {
    color: teal !important;
  }

  .ckeckOff {
    color: red !important;
  }

  