.liGuardias {
  width: auto;
  height: 64px;
  padding: 23px 16px 23px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  cursor: pointer;
  /* list-style: none; */
}

.article {
  width: 100%;
  height: 332px;
  background: rgba(17, 88, 150, 0.9);
  position: absolute;
  top: 154px;
  left: 0px;
  z-index: 3;
  display: none;
}

.visible {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  padding-top: 40px;
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.liGuardias:hover {
  background: #e2b900;
  height: 58px;
}

/* .liGuardias:hover > article {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
} */

.liGuardias figure {
  display: none;
}

.liGuardias:hover > figure {
  display: flex;
  align-items: flex-start;
  margin-top: 0px;
  z-index: 3;
  position: absolute;
  top: 150px;
  padding: 0px;
}

.liGuardias section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 1128px;
  height: 227px;
}

/*   ---------------------------------------------------------------------------------------------------- */

.megaMenuGuardias {
  width: 552px;
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  transition: all ease-in-out .3s;
}

.megaMenuGuardias p,
.megaMenuGuardias img{
  transition: all ease-in-out .3s;
}
.megaMenuGuardias:hover p {
  transform: scale(1.25);
  color: #e2b900;
}
.megaMenuGuardias:hover .img {
  transform: scale(1.25) translateY(-5px);
  filter: brightness(0) saturate(100%) invert(61%) sepia(100%) saturate(665%)
    hue-rotate(10deg) brightness(97%) contrast(111%);
}
.megaMenuGuardiasBaby {
  width: 552px;
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out .3s;
}

.megaMenuGuardiasBaby p,
.megaMenuGuardiasBaby img{
  transition: all ease-in-out .3s;
}
.megaMenuGuardiasBaby:hover p {
  transform: scale(1.25);
  color: #e2b900;
}
.megaMenuGuardiasBaby:hover .img {
  transform: scale(1.25) translateY(-5px);
  filter: brightness(0) saturate(100%) invert(61%) sepia(100%) saturate(665%)
    hue-rotate(10deg) brightness(97%) contrast(111%);
}

.megaMenuGuardiasBaby p {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  margin: 8px 0px 0px 0px;
}

.megaMenuGuardiasBaby picture {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.megaMenuGuardias div {
  display: flex;
  flex-direction: row;
}

.megaMenuGuardias picture {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.megaMenuGuardias p {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  margin: 8px 0px 0px 0px !important;
}

/*   ------------------------------------------------------------------------------------------ */

.navBarTitle {
  height: 20px;
  color: #fff;
  text-decoration: none;
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  display: inline;
}

.navBarTitle:hover {
  color: white;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
