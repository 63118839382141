.body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e5e5e5;
}

.arrowContainer {
  width: 1128px;
  display: flex;
  justify-content: flex-start;
  margin: 37.64px 0px 0px 0px;
}

.goToHome {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 250%;
  letter-spacing: -0.32px;
  color: #115896;
}

.arrow {
  margin-right: 6.22px;
}

.containerCards {
  width: 1120px;
  border-radius: 6px;
  background: #ffffff;
  padding: 40px;
  margin-bottom: 56px;
}

.title {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: -0.32px;
  color: #002856;
  text-align: start;
}

.sectionCards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.line {
  margin: 0px 0px 24px 0px;
}

/* ---------------------------------------------------------------------------------------------------
                                        MEDIA QUERY
--------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1175px) {
  .containerCards {
    width: 744px;
  }

  .arrowContainer {
    width: 744px;
  }

  .line {
    width: 664px;
  }
}

@media only screen and (max-width: 791px) {
  .containerCards {
    width: 560px;
  }

  .arrowContainer {
    width: 560px;
  }

  .title {
    font-size: 22px;
  }

  .arrowContainer {
    margin: 37.64px 0px 0px 0px;
  }

  .goToHome {
    font-size: 12px;
  }

  .line {
    width: 480px;
    /* border: solid 0.01rem #EAEDED; */
  }
}

@media only screen and (max-width: 607px) {

    .arrowContainer {
        display: none;
      }

      .arrowContainerResponsive {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: 13px 0px 12px 0px;
      }

      .containerCards {
        width: 100%;
        border-radius: 0px;
        background: #ffffff;
        padding: 0px 24px 22px 24px;
        margin-bottom: 0px;
      }


    }