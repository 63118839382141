.modalFather {
  z-index: 990;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
}

.btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
}

.img {
  width: 21px;
  height: 21px;
}

.modalContainer {
  z-index: 998;
  background-color: #ffffff;
  position: relative;
  width: 480px;
  height: 480px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.imgBanner {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.load {
  width: 70px;
  height: 70px;
}

.false {
  display: none;
}

@media only screen and (max-width: 607px) {
  .modalContainer {
    width: 272px;
    height: 480px;
  }
}
