.homeContainer {
  width: 100%;
  height: 100%;
}

.navBar__Home {
  position: fixed;
  z-index: 980;
  width: 100vw;
}
.distancia{
  margin-top: 154px !important;
}

@media only screen and (max-width: 608px) {
  .distancia{
    margin-top: 112px !important;
  }
}