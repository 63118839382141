.container__main {
  width: 100%;
  height: 224px;
  position: relative;
  overflow: hidden;
}

.container__content {
  width: 1128px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 24px auto;
}

.imagen__background {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.container__frame1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.container__frame1 img {
  width: 55px;
  height: 48px;
}

.container__frame2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
}

.container__frame2 img {
  width: 71px;
  height: 62px;
}

.container__title_image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
}

.container__title_image h3 img {
  width: 36px;
  height: 36px;
  margin-right: 12px;
}

.container__title_image h3 {
  margin: 0;
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.32px;
  font-weight: 700;
  font-family: Merriweather;
  display: flex;
  align-items: center;
}

/* Media query para tablet grande */
@media screen and (max-width: 1175px) {
  .container__content {
    width: 100%;
  }
  .container__frame1 {
    margin-left: 24px;
  }
  .container__frame2 {
    right: 24px;
  }

  .container__title_image {
    width: 744px;
    right: 0;
    left: 0;
    margin: auto;
  }
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .container__frame2 {
    display: none;
  }
  .container__title_image h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.82px;
  }

  .container__title_image h3 img {
    width: 28px;
    height: 28px;
  }

  .container__title_image {
    width: 560px;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__frame1 {
    display: none;
  }
  .container__title_image {
    width: 100%;
    padding: 0 24px;
    text-align: left;
  }
  .container__title_image h3 {
    align-items: flex-start;
  }
  .container__title_image h3 img {
    margin-top: 5px;
  }
}
