.currentContainer {
    width: 800%px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tableContainer {
    z-index: 0;
    /* position: absolute; */
    width: 90%;
    display: flex;
    justify-content: center;
}

.currentContainer table {
    width: 800px;
}

.tableButton {
border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  width: 100px;
  height: 30px;
}

.modal {
  position: fixed !important;
  z-index: 999 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  min-height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalNew {
  position: fixed !important;
  z-index: 999 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  min-height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalNewOff {
  display: none;
}

.modalOff {
    display: none;;
}

.modalContainer {
position: relative;
  padding: 1rem;
  width: 782px !important;
  height: 70% !important;
  overflow-y: auto;
  background-color: #fff;
}

.newButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  margin: 20px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancelButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  border: solid 2px red;
  background-color: white;
  color: red;
  cursor: pointer;
  margin-right: 20px;
  margin: 20px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonNewContainer {
  width: 800px;
  /* border: solid 2px black; */
  display: flex;
  justify-content: flex-start;
}

.formContainerSection {
  width: 800px;
}

.formNewSection {
  display: flex;
  /* justify-content: flex-start; */
  /* flex-direction: column; */
  align-items: center !important;
}

.formNewSection div {
  display: flex;
  flex-direction: row;
  padding-left: 25px;
}

.formNewSection label {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.formNewSection aside {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.formNewSection input {
  align-items: center;
  height: 30px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.thAction {
  width: 400px;
}

.tdSection {
  display: table-cell;
   vertical-align: middle;
}

.deleteButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: red;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  margin: 20px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerTable {
  color: white;
  background: #002856;
  display: table-row;
  vertical-align: middle;
}

.finishButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.containerButtonGroup {
  width: 782px;
  display: flex;
  justify-content: space-around;
  background-color: white;
  border-top: solid 1px lightgray;
}

.markdown-button {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: red;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  margin: 20px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modified-button {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #002856;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  margin: 20px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  margin: 20px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveDisabled {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: grey;
  color: lightgray;
  margin-right: 20px;
  margin: 20px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:auto;
}

.modalImageContainer {
  display: flex;
}

.modalImageContainerOff {
  display: none;
}