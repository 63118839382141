.edit-icon-button-tel {
  width: 150px;
  background-color: white;
  border: #2471A3 2px solid;
  border-radius: 10px;
}

.edit-icon-button-estadisticas {
  width: 150px;
  background-color: white;
  border: teal 2px solid;
  border-radius: 10px;
}

.edit-icon-button-email {
  width: 150px;
  background-color: white;
  border: #E74C3C 2px solid;
  border-radius: 10px;
}
