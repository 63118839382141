.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.create {
  width: 100%;
  height: 50px;
  padding: 0px 20px 0px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.crear {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.form {
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.title {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.titleLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.titleInput {
  width: 100% !important;
}

.specialty {
  margin-top: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.specialtyLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.labsLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.selectArea {
  border: none;
  margin: 10px 0px 10px 0px;
}

.spanContainer {
  margin: 10px;
}

.cancel {
  color: red;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.labs {
  margin-top: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.documents {
  margin-top: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.documentsLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.documentsInput {
  margin: 10px 0px 10px 0px;
}

.indications {
  margin-top: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  padding: 10px;
}

.indicationsLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.indicationsContainerButton {
  margin-top: 10px;
}

.indicationsButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px 5px;
  background-color: #002856;
  color: white;
  cursor: pointer;
  width: 120px;
  height: 40px;
  background-color: #002856;
}

.ayuno {
  margin-top: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  padding: 10px;
}

.ayunoLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.inputFast {
  border: solid red 1px;
}

.fastButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px 5px;
  background-color: #002856;
  color: white;
  cursor: pointer;
  width: 100px;
  height: 40px;
  background-color: #002856;
}

.inputFast:checked {
  background: teal !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .sliderRound {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.ayunoContainerInput {
  margin-top: 10px;
}

.ayunoContainerInput label {
  margin-right: 10px;
}

.specialtyMarkdown {
  margin-top: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.headerTable {
  height: auto;
  width: 100%;
}

.table {
  width: 100%;
}

.headerTable {
  color: white;
  background: #002856;
  display: table-row;
  vertical-align: middle;
}

.thAction {
  width: 50%;
}

.tdSection {
  display: table-cell;
  vertical-align: middle;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal {
  position: fixed !important;
  z-index: 999 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  min-height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalContainer {
  position: relative;
  padding: 1rem;
  width: 1000px !important;
  height: 70% !important;
  overflow-y: auto;
  background-color: #fff;
}

.containerButtonGroup {
  width: 1000px;
  display: flex;
  justify-content: space-around;
  background-color: white;
  border-top: solid 1px lightgray;
}

.documentInput {
  display: none;
}

.searchButton {
    border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #002856;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  margin: 20px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
    background-color: #002856;
}

.searchFile:hover {
    color:teal;
}

.selectContainer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  align-items: center;
}

.selectContainer label {
  font-size: 18px;
}

.searchContainer {
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 10px;
}

.file {
  width: 50px;
  height: 50px;
  color: red;
  cursor: pointer;
}

.sendContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.sendButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #002856;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
    background-color: #002856;
}

.cancelContainer {
  display: flex;
  flex-direction: column;
}

.cancelFiles {
  /* width: 800px; */
}