.pForm {
  color: #ff0000;
  text-align: start;
}

input[type="text"],
/* input[type="email"], */
input[type="number"],
input[type="tel"],
textarea {
  border: thin solid #dedede;
  border-radius: 0.25rem;
  padding: 3px;
  margin-bottom: 3px;
  outline: none;
  display: block;
  width: auto;
  font-size: 15px;
  line-height: 1;
  background-color: transparent;
}

.textareaForm {
  font-family: inherit;
  resize: none;
}

button,
input[type="submit"],
input[type="reset"] {
  width: 40%;
  border: thin solid #444;
  border-radius: 0.25rem;
  padding: 2px;
  margin: 0 2px 0 0;
  display: inline-block;
  background-color: #eee;
  color: #444;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.contactForm {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: auto;
  margin-bottom: 10px;
}

.labelForm {
  text-align: start;
  padding: 10px;
  font-size: 15px;
}
