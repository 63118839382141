:root{
  --main-blue-color: #002856;
  --main-light-blue-color: #115896;
  --main-yellow-color: #e2b900;
  --main-hover-default-color: rgba(17, 88, 150, 0.1);
}

html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
::placeholder {
  color: rgba(0, 0, 0, 0.26);
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}
