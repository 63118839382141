#turno {
  width: 122px;
  height: 32px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 27px;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.32px;
  color: #ffffff;
  background: #002856;
}

#chat {
  width: 72px;
  height: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #e2b900;
  border-radius: 4px;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  color: #ffffff;
  margin-right: 23px;
}
