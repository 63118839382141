.titleContainer {
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 32px;
  font-family: Merriweather Sans;
  font-weight: 700;
  color: #002856;
}
.img {
  display: none;
}
.divisor {
  height: 1px;
  background-color: rgba(17, 88, 150, 0.15);
}
.desc {
  font-size: 14px;
  font-family: Merriweather Sans;
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 24px;
  color: #747678;
}
.searchBar {
  position: relative;
  /* margin-bottom: 32px; */
  margin-top: 16px;
  width: fit-content;
}
.search {
  width: 336px !important;
  height: 40px;
  border: 1px solid #115896 !important;
  border-radius: 6px !important;
  font-family: Merriweather Sans;
  padding: 7px 12px !important;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: -0.32px;
  margin-bottom: 0 !important;
  outline: none;
}
.lupa {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.subtitle {
  font-family: Merriweather Sans;
  font-size: 16px;
  font-weight: 800;
  margin-top: 24px;
  margin-bottom: 16px;
  color: #115896;
}
.filter {
  display: flex;
  margin-bottom: 32px;
}
.filter2 {
  display: flex;
  margin-bottom: 32px;
}
.filtros_line {
  display: flex;
}
.filtrado {
  color: #115896;
  font-size: 18px;
  font-weight: 700;
  font-family: Merriweather Sans;
  width: 56px;
  height: 48px;
  /* margin: 12px 16px 12px 16px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
}
.filtrado:hover {
  background: rgba(17, 88, 150, 0.1);
}
.filtrado_active {
  background-color: #115896;
  color: white;
  font-size: 18px;
  font-weight: 700;
  font-family: Merriweather Sans;
  width: 56px;
  height: 48px;
  /* margin: 12px 16px 12px 16px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
}
.separacion_filtrado {
  width: 2px;
  height: 48px;
  background-color: #747678;
  margin: 0 4px 0 4px;
  background-color: rgba(17, 88, 150, 0.15);
}
.body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
}
.body2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 24px;
}
.abc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  }
.letterIndex {
  width: 100%;
  background-color: #fdf5ea;
  border-radius: 6px;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 4px;
}
.letter {
  font-size: 24px;
  font-weight: 700;
  font-family: Merriweather Sans;
  color: #002856;
  margin: 0;
}
.studyName {
  color: #115896;
  font-family: "Merriweather Sans";
  /* font-weight: 700; */
  font-size: 16px;
  margin: 2px 0 2px 0;
  min-height: 40px;
  height:max-content;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.studyNameActive {
  color: #115896;
  font-family: "Merriweather Sans";
  font-weight: 700;
  font-size: 16px;
  margin: 2px 0 2px 0;
  /* padding: 2px 0 2px 0; */
  height: max-content;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.titleStudy{
  font-size: 24px;
  font-weight: 700;
  color: #115896;
  font-family: "Merriweather Sans";
  margin-top: 8px;
  margin-bottom: 16px;
}
.title_subtitle{
  font-size: 16px;
  font-weight: 700;
  font-family: "Merriweather Sans";
  color: #002856;
}
.info {
    /* border: 1px red solid; */
    height: fit-content;
    background: rgba(17, 88, 150, 0.04);
    padding: 16px;
    border-radius: 6px;
  }
.prep_title {
  font-size: 18px;
  width: 288px;
  font-weight: 700;
  font-family: "Merriweather Sans";
  color: #002856;
  margin-bottom: 4px;
}
.prep_subtitle {
  font-size: 16px;
  width: 288px;
  font-weight: 700;
  font-family: "Merriweather Sans";
  color: #115896;
  margin-top: 12px;
}
.ayunoContainer {
  display: flex;
  justify-content: space-between;
  height: max-content;
  margin-bottom: 8px;
}
.ayunoContainer p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Merriweather Sans";
  color: #575757;
  margin: 0;
}
.prep_btn {
  width: 288px;
  height: 40px;
  background-color: #115896;
  border: none;
  font-size: 12px;
  color: white;
  font-weight: 400;
  font-family: "Merriweather Sans";
  margin-top: 24px;
  border-radius: 4px;
}
.ind_btn {
  width: 212px;
  height: 40px;
  background-color: #115896;
  border: none;
  font-size: 12px;
  color: white;
  font-weight: 400;
  font-family: "Merriweather Sans";
  margin-top: 24px;
  border-radius: 4px;
}
.false {
  display: none;
}
.recomendaciones {
  width: 100%;
  max-height: 145px;
  background-color: #fff;
  position: absolute;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  border-radius: 6px;
  margin-top: 4px;
  overflow-y: auto;
  overflow-x: hidden;
}
.recomendaciones::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

.recomendaciones::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

.recomendaciones::-webkit-scrollbar-thumb {
  background-color: #79797986;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  /* border: 3px solid orange;  creates padding around scroll thumb */
}
.option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: max-content;
  max-height: 40px !important;
  width: 296px;
  margin: 5px 0;
  color: #115896;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  padding-left: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.desplegable {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}
.desplegable_title {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  font-family: "Merriweather Sans";
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 0 !important;
  height: max-content !important;
  text-decoration: none !important;
  background-color: rgba(17, 88, 150, 0.04) !important;
}
.desplegable_title p {
  margin: 3px 0 0px 14px;
  /* margin-left: 14px; */
}
.trash_btn {
  background-color: transparent;
  border: none;
  height: max-content;
  width: max-content;
}
.desplegable_title:not(.collapsed) {
  /* font-size: 14px; */
  font-weight: 700;
  color: #002856 !important;
  background-color: rgba(17, 88, 150, 0.04) !important;
  box-shadow: none !important;
  text-decoration: none !important;
}
.desplegable_title::after {
  display: none !important;
}
.p {
  text-decoration: none !important;
  width: 258px;
  margin: 3px;
}
.title_desplegable{
  padding: 16px 0 5px 0;
  border-bottom: var(--main-hover-default-color) 1px solid;
  background-color: rgba(17, 88, 150, 0.04) !important;
  width: 100%;
  margin-right: 0;
}

.title_desplegable:not(.collapsed){
  color: #115896 !important;
  font-size: 16px;
  font-weight: 700;
  box-shadow: none !important;
  text-decoration: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0 !important;
  padding-bottom: 5px !important;
  background-color: rgba(17, 88, 150, 0.04) !important;
}
.title_desplegable p {
  margin: 0;
  margin-bottom: 2px;
}
.msg {
  font-size: 14px;
  font-family: "Merriweather Sans";
  color: rgba(87, 87, 87, 0.5);
  margin: 0;
  margin-top: 8px;
}

.content {
  font-weight: 400;
  font-family: "Merriweather Sans";
  color: #575757;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 62px;
  margin: 0 auto;
  margin-top: 8px;
font-size: 14px;
line-height: 1.5;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;

}
.content2 {
  font-weight: 400;
  font-size: 14px;
  font-family: "Merriweather Sans";
  color: #575757;
  display: block;
  /* display: -webkit-box; */
  width: 100%;
  /* height: 62px; */
  margin: 0 auto;
  margin-top: 8px;
  line-height: 1.5;
  overflow:unset;
}
.content1 p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Merriweather Sans";
  margin: 0;
  margin-top: 8px;
}
.link {
  color: #115896;
  font-size: 14px;
  font-weight: 700;
  font-family: "Merriweather Sans";
  cursor: pointer;
}

.pContainer {
  width: auto;
  height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.modal{
  position: fixed;
  z-index: 1000;
  top: 0 !important;
  left: 0 !important;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeModal {
  position: absolute;
  z-index: 3;
  right: 20px;
  top: 20px;
  width: 40px;
  cursor: pointer;
}
.closeModal1 {
  position: absolute;
  z-index: 3;
  right: 20px;
  top: 25px;
  width: 30px;
  cursor: pointer;
}

.none{
  display: none;
}
.cont{
  width: 288px;
}
.divModal{
  background-color: white;
  width: 400px;
  height: max-content;
  z-index: 3;
  position: absolute;
  border-radius: 6px;
  padding: 24px;
  max-height: 400px;
  overflow-y: auto;
}
.contentModal{
  z-index: 4;
  color: black;
}
.containerModal{
  display: flex;
  align-items: center;
  height: max-content;
}
.containerModal h2{
  margin-bottom: 0;
  font-size: 24px;
  font-family: "Merriweather Sans";
  color: #115896;
  margin-right: 20px;
}
.Accordion_Item{
  width: 288px;
  background: rgba(17, 88, 150, 0.01) !important;
}
.Accordion_Header button{
  padding: 0 !important;
}
.Accordion_Heder button{
  padding: 0 !important;
}
.Accordion_Heder button::after{
  display: flex !important;
  margin-right: 24px !important;
  margin-top: -28px !important;
  margin-bottom: 8px !important;
}

.contenidocentral__content_filtrado {
  height: fit-content;
}
@media screen and (max-width: 1175px) {
  .img {
    display: flex;
    cursor: pointer;
  }
}
@media screen and (max-width: 791px) {
  .abc {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 24px;
    }
  .abc1 {
    display: none;
  }
  .prep_title{
    width: 100%;
  }
  .prep_subtitle{
    width: 100%;
  }
  .cont{
    width: 100%;
  }
  .Accordion_Item{
    width: 100%;
  }
  .desplegable_title{
    width: 100%;
    justify-content: space-between;
  }
  .p{
    width: 100%;
  }
  .filter{
    
      /* margin-left: -20px; */
      display: -webkit-inline-box;
      justify-content: center;
      width: 100%;
      height: auto;
      flex-wrap: wrap;
    
  }
  .filter2 {
    display: none;
  }
  .subtitle {
    display: none;
  }
  .searchBar {
    margin-bottom: 24px;
  }
  .body {
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 607px) {
  .titleContainer {
    margin-top: 16px;
  }
  .divModal{
    background-color: white;
    width: 280px;
    height: max-content;
    z-index: 3;
    position: absolute;
    border-radius: 6px;
    padding: 24px;
    max-height: 400px;
    overflow-y: auto;
  }
}
@media screen and (max-width: 385px) {
  .searchBar {
    width: 100% !important;
  }
  .search {
    width: 100% !important;
  }
}
@media screen and (max-width: 366px) {
  .prep_btn{
    width: 100%;
  }
}
