.BackofficeImageControl-container {
    width: 100%;
    height: 100vh !important;
}

.imageControl-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.sidebar {
    /* flex: 1; */
    width: auto;
    min-height: 100%;
    /* border: solid 3px yellow; */
}

.backofficeNav {
    width: auto;
    margin: 10px 0 10px 0;
    position: sticky;
    top: 0;
    z-index: 999;
}

.nav-image {
    width: 100%;
    /* height: 10%; */
    margin-top: 8px;
}

.article-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    /* border: solid black 5px; */
    margin-top: 20px;
}
