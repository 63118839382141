.main{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 388px;
    width: 100vw;
    background: linear-gradient(0deg, rgba(17, 88, 150, 0.05), rgba(17, 88, 150, 0.05)), #FAFAFA;
}
.container{
    width: 272px;
    height: 228px;
    /* background-color: red; */
}
.title{
    font-size: 32px;
    font-weight: 700;
    font-family: "Merriweather Sans";
    color: rgba(17, 88, 150, 0.75);
    margin-bottom: 4px;
    margin-top: 4px;
}
.txt{
    font-family: 'Merriweather Sans';
    font-weight: 400;
    font-size: 16px;
    color: rgba(17, 88, 150, 0.75);
    letter-spacing: -0.32px;
    line-height: 24px;
}
.btn{
    width: 119px;
    height: 32px;
    background-color: #115896;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Merriweather Sans';
}