.container__main {
  width: 100%;
  margin-top: 24px;
}

.container__content {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.container__btn {
  cursor: pointer;
  width: 107px;
  height: 32px;
  background-color: #e2b900;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 24px;
}

.container__btn span {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  color: #002856;
}

.container__btn:hover span,
.container__btn:hover {
  background: #e6c121;
  color: #073a74;
}

.container__title {
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(17, 88, 150, 0.15);
  text-align: left;
  margin-bottom: 24px;
}

.container__title h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  color: #115896;
}

/* Media query para tablet chica  */
@media screen and (max-width: 791px) {
  .container__title h3 {
    font-size: 24px;
    line-height: 32px;
  }
}
