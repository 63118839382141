.containerGral {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.datagridContainer {
    width: 100%;
    height: 100%;
}

.linkCreate {
    margin: 0px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    margin: 0px 0px 20px 20px;
}

.userAddButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
  }

.title {
    width: 80%;
    display: flex;
    justify-content: center;
}

.title span {
    font-family: Merriweather Sans;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 40px;
letter-spacing: -0.32px;

/* Paleta Web/Azul Oscuro */

color: #002856;
}

.tableContainer {
    width: 90%;
}