.containerGral {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.iconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.iconContainer img {
    filter: brightness(0) saturate(100%) invert(9%) sepia(40%) saturate(6198%) hue-rotate(201deg) brightness(89%) contrast(101%);
    width: 30px;
}

.imageContainer {
    width: 100%;
    height: 90%;
    /* padding: 10px; */
    display: flex;
    justify-content: center;
}

.image {
    width: 100%;
    height: auto;
    margin: 5px;
    border-radius: 6px;
    object-fit: cover;
}

.createContainer {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
}

.newButtonCreate {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: #002856;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}