.liPacientes {
  width: auto;
  height: 64px;
  padding: 23px 16px 23px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  cursor: pointer;
  /* list-style: none; */
}

.article {
  width: 100%;
  height: 332px;
  background: rgba(17, 88, 150, 0.9);
  position: absolute;
  top: 154px;
  left: 0px;
  z-index: 3;
  display: none;
}

.visible {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  padding-top: 40px;
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.liPacientes:hover {
  background: #e2b900;
  height: 58px;
}

/* .liPacientes:hover > article {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  padding-top: 40px;
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both; */
/* } */

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.liPacientes figure {
  display: none;
}

.liPacientes:hover > figure {
  display: flex;
  align-items: flex-start;
  margin-top: 0px;
  z-index: 3;
  position: absolute;
  top: 150px;
  padding: 0px;
}

.liPacientes section {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* align-items: flex-start; */
  width: 1128px;
  height: 227px;
  padding-left: 0px;
}

/*  ------------------------------------------------------------------------------------------------------------------------------ */

.megaMenuPacientes {
  width: 264px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 120px;
}

.megaMenuPacientesProfesionales {
  width: 277px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 107px;
}

.megaMenuPacientes div {
  display: flex;
  flex-direction: column;
}

.pacientesUl {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: flex-start;
  padding: 0px;
  width: 360px;
  height: 100%;
}

.megaMenuPacientes li {
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: flex-start;
}

/* --------------------------------------------------------------------------------------------------------------------------------- */

.megaMenuPacientes a {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140.69%;
  color: #ffffff;
  border: none;
  text-decoration: none;
  text-align: start;
  margin: 8px 0px 8px 0px;
}

.iconsNavContainer {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 8px 8px 0px;
}

.iconsNav {
  width: 20px;
  height: 18px;
}

.megaMenuTitleContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid white;
  width: 100%;
  min-height: 32px;
}

.megaMenuTitle {
  display: flex !important;
  flex-direction: row !important;
  width: 277px;
  height: 24px;
  padding: 0px;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  /* border: solid yellow 1px; */
}

.megaMenuPcontainer {
  width: 204px;
  height: 18px;
  margin: 3px 0px 11px 0px;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  /* border: solid red 1px; */
}

.megaMenuP {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  text-align: start;
}

.megaMenuPcontainerProfesional {
  width: auto;
  height: 18px;
  margin: 3px 0px 11px 0px;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  text-align: start;
  /* border: solid red 1px; */
}

.institucionalLink {
  width: auto;
  height: 23px;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  margin-top: 16px;
}

.institucionalLink a {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140.69%;
  color: #ffffff;
  margin: 0px;
  text-decoration: none;
}

.institucionalLink span{
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140.69%;
  color: #ffffff;
  margin: 0px;
  text-decoration: none;
}

.institucionalLink a:hover {
  color: #e2b900;
}
.institucionalLink span:hover {
  color: #e2b900;
}

#lineNav {
  padding: 0px;
  margin: 0px;
  color: #fff;
}

.navBarTitle {
  height: 20px;
  color: #fff;
  text-decoration: none;
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  display: inline;
}

.navBarTitle:hover {
  color: white;
}

/*   ------------------------------------------------------------------------------------------------------------ */

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
