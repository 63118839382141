.container {
  width: 100%;
  height: 224px;
  background: linear-gradient(
    0deg,
    rgba(17, 88, 150, 0.5),
    rgba(17, 88, 150, 0.5)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter {
  width: 100%;
  height: 224px;
  background: linear-gradient(
    0deg,
    rgba(17, 88, 150, 0.5),
    rgba(17, 88, 150, 0.5)
  );
  z-index: 1;
  position: absolute;
}

.containerElements {
  width: 1128px;
  height: 158px;
  padding: 0 2px 0 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
}

.frameContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.frameAndOtherContainers {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.titleContainer {
  height: 45px;
  width: auto;
  display: flex;
  align-items: center;
}

.agenda {
    margin-bottom: 5px;
}

.title {
  margin-left: 16.5px;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.32px;
  color: #ffffff;
}

.mask {
  height: 224px;
  width: 100%;
  object-fit: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
}

/* ---------------------------------------------------------------------------------------------------
                                        MEDIA QUERY
--------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1175px) {

  .containerElements {
    width: 100%;
    height: 158px;
    padding: 0 2px 0 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .frameAndOtherContainers {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }

  .frame1 {
    margin-left: 15px;
  }

  .frame2 {
    position: absolute;
    top: 295px;
    right: 24px;
  }

  .titleContainer {
    width: 744px;
  }

  /* .responsive {
    width: 100;
  } */

}

@media only screen and (max-width: 791px) {

  .titleContainer {
    width: 560px;
  }
}

@media only screen and (width: 608px) {

  .frame1 {
    margin-top: 25px;
  }

  .frame2 {
    display: none;
  }

}

@media only screen and (max-width: 607px) {
  .frame1 {
    display: none;
  }

  .frame2 {
    display: none;
  }

  .titleContainer {
    height: auto;
    margin-left: 24px;
    display: flex;
    align-items: flex-start;
  }

  .title {
    text-align: start;
  }

  .agenda {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 360px) {

  .frame1 {
    display: none;
  }

  .frame2 {
    display: none;
  }

  .titleContainer {
    /* width: 236px; */
    height: auto;
    margin-left: 24px;
    display: flex;
    align-items: flex-start;
  }

  .title {
    text-align: start;
  }

  .agenda {
    margin-top: 8px;
  }

}