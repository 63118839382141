@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.containerGral {
  margin: 0px 33px 58px 33px;
  border-radius: 6px !important;
  width: fit-content;
  filter: drop-shadow(0px 4px 4px rgba(17, 88, 150, 0.5));
  cursor: pointer;
}

.containerGral:hover {
  height: 344px;
  border-radius: 6px 6px 0px 0px;
}

.containerGral:hover aside {
  width: 210px;
  height: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: solid red 2px; */
  padding: 0;
  margin: 0;
  /* position: absolute; */
  z-index: 6;
  border-radius: 6px;
  -webkit-animation: fade-in-bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.containerGral:hover .front {
  margin-bottom: 0;
}

.containerGral:hover .filter {
  /* position: absolute; */
  top: 0;
  height: 264px;
  z-index: 2;
  border-radius: 6px 6px 0px 0px;
  background: linear-gradient(0deg, #002856 19.3%, rgba(17, 88, 150, 0) 84.55%);
}

.containerGral:hover .backgroundImage {
  z-index: 1;
  height: 264px;
  /* object-fit: cover; */
  /* position: absolute;
    top:0; */
  border-radius: 6px 6px 0px 0px;
  border: color orange 2px;
}

.containerGral:hover .icon {
  width: 35px;
}

.backgroundImage {
  width: 210px;
  height: 344px;
  border-radius: 6px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: height 0.3s linear;
}

.containerGral:hover .radius {
  display: flex;
}

.radius {
  background: linear-gradient(
    180deg,
    #002856 19.3%,
    rgba(17, 88, 150, 0) 84.55%
  );
  border-radius: 0px 0px 6px 6px;
  display: none;
}

.card {
  width: 210px;
  height: 344px;
  padding: 0px 18px 0px 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: solid orange 1px;
  z-index: 1;
  border-radius: 6px;
  position: relative;
}

.background {
  z-index: 1;
  width: 210px;
  height: 344px;
  object-fit: cover;
  border-radius: 6px;
  position: absolute;
}

.front {
  margin-bottom: 32px;
  z-index: 5;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.filter {
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #002856 19.3%, rgba(17, 88, 150, 0) 84.55%);
  z-index: 2;
  /* position: absolute; */
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.icon {
  width: 50px;
  margin-bottom: 17px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7499%)
    hue-rotate(183deg) brightness(96%) contrast(104%);
  z-index: 5;
  /* position: absolute; */
}

.nameContainer {
    width: 174px;
    height: auto;
}

.name {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-align: center;
  z-index: 5;
}

.resumeContainer {
  width: 210px;
  height: 5px;
  background: #ffffff;
  border-radius: 6px !important;
  padding: 12px 14px 12px 14px !important;
  display: none;
}

.box {
  width: 100%;
  height: 100%;
  /* border: solid red 1px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.resumeContainer:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #002856;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moreContainer {
  display: flex;
  flex-direction: row;
}

.moreContainer span {
  margin-right: 10.67px;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #cd9700;
}

/* ---------------------------------------------------------------------------------------------------
                                        MEDIA QUERY
--------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1175px) {

  .containerGral {
    height: 344px;
    margin: 0px 8px 18px 8px;
  }

  .backgroundImage {
    height: 249px;
    border-radius: 6px 6px 0px 0px;
  }

  .radius {
    display: flex;
  }

  .resumeContainer {
    width: 210px;
    height: 95px;
    background: #ffffff;
    border-radius: 6px !important;
    padding: 12px 14px 12px 14px !important;
    display: flex;
    z-index: 6;
    /* position: absolute; */
  }

  .filter{
    border-radius: 6px 6px 0px 0px;
  }
}

@media only screen and (max-width: 791px) {

    .containerGral {
        height: 258px;
        margin: 0px 8px 18px 8px;
      }

    .backgroundImage {
        height: 163px;
        border-radius: 6px 6px 0px 0px;
      }

}
