.container__main {
    width: 100%;
    position: relative;
  }
  
  .container__content {
    position: relative;
    width: fit-content;
    display: grid;
    grid-template-columns: 360px 744px;
    grid-template-areas: "columnIzq columnDer";
    grid-template-rows: auto;
    justify-items: center;
    margin: 0 auto;
    margin-bottom: 56px;
    column-gap: 24px;
  }
  
  .container__flecha_home {
    width: 1128px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    margin-top: 24px;
  }
  
  .container__flecha_home_content {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .container__flecha_home_content span {
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.32px;
    color: #115896;
    font-family: Merriweather Sans;
  }
  
  .flecha__home {
    width: 20px;
    height: 20px;
    margin: 0 8px;
  }
  
  /* Media query para tablet grande */
  @media screen and (max-width: 1175px) {
    .container__content {
      grid-template-columns: auto 0px;
      gap: 0px;
      grid-template-areas: "columnDer columnIzq";
    }
    .container__flecha_home {
      width: 744px;
    }
  }
  
  /* Media query para tablet chica */
  @media screen and (max-width: 791px) {
    .container__flecha_home {
      width: 560px;
      height: 24px;
    }
    .container__flecha_home_content span {
      font-size: 12px;
      line-height: 15px;
    }
    .flecha__home {
      width: 12px;
      height: 12px;
    }
  }
  
  /* Media query para movil */
  @media screen and (max-width: 607px) {
    .container__content {
      margin: 0;
      width: 100%;
      justify-content: initial;
    }
  
    .container__flecha_home {
      width: 100%;
      position: absolute;
      z-index: 1;
    }
    .container__flecha_home_content {
      position: relative;
      left: 24px;
      bottom: 13px;
    }
    .container__flecha_home_content img {
      margin-left: 0;
    }
  }
  