.container__main {
  width: 100%;
  margin: auto;
  margin-top: 24px;
}
.container__title {
  width: 100%;
  height: 40px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(17, 88, 150, 0.15);
  text-align: left;
  margin-bottom: 24px;
}

.container__title h3 {
  color: #115896;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.32px;
  font-weight: 700;
  font-family: Merriweather Sans;
}

.container__images {
  width: 100%;
  height: 138px;
  overflow: hidden;
}

.imagenSlide {
  width: 208px;
  height: 138px;
  border-radius: 6px;
  cursor: pointer;

  object-fit: cover;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.overlay_container__image {
  position: relative;
}
.closeBtn {
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}
.overlay img {
  width: 736px;
  height: 488px;
  border-radius: 6px;
  object-fit: cover;
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .container__content {
    width: 512px;
  }
  .container__images {
    padding: 0 38px;
  }
  .overlay img {
    width: 560px;
    height: 372px;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container__images {
    width: 286px;
    padding: 0 auto;
  }
  .overlay img {
    width: 272px;
    height: 180px;
  }
  .closeBtn {
    width: 24px !important;
    height: 24px !important;
    right: 8px;
    top: 8px;
  }
}
