.liPrincipal {
  width: auto;
  height: 64px;
  padding: 23px 16px 23px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  cursor: pointer;
  /* list-style: none; */
}

.article {
  width: 100%;
  height: 332px;
  background: rgba(17, 88, 150, 0.9);
  position: absolute;
  top: 154px;
  left: 0px;
  z-index: 3;
  display: none;
  padding-top: 40px;
}
.visible {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  padding-top: 40px;
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.liPrincipal:hover {
  background: #e2b900;
  height: 58px;
}
/* 
.liPrincipal:hover > article {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  padding-top: 40px;
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
} */

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.liPrincipal figure {
  display: none;
}

.liPrincipal:hover > figure {
  display: flex;
  align-items: flex-start;
  margin-top: 0px;
  z-index: 3;
  position: absolute;
  top: 150px;
  padding: 0px;
}

.liPrincipal section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1128px;
  height: 227px;
}

/* -------------------------------------------------------------------------------------------------------------------- */

.megaMenuContainer {
  width: 260px;
  height: 227px;
  display: flex;
  flex-direction: column;
}

.megaMenuTitleContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid white;
  width: 100%;
  min-height: 32px !important;
}

.megaMenuContainer ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  /* justify-content: flex-start; */
  padding: 0px;
  height: 100%;
}

.megaMenuContainer li {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: flex-start;
}

/*   ---------------------------------------------------------------------------------------------------------------------- */

.megaMenuContainer a {
  text-align: start;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140.69%;
  color: #ffffff;
  border: none;
  text-decoration: none;
  margin: 8px 0px 8px 0px;
}

.institucionalLink {
  width: 236px;
  height: 23px;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  margin-top: 16px;
}

.institucionalLink a {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140.69%;
  color: #ffffff;
  margin: 0px;
}

.institucionalLink a:hover {
  color: #e2b900;
}

#curf {
  height: 40px !important;
}

.iconsNavContainer {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
}

.iconsNav {
  width: 20px !important;
  height: 18px !important;
  /* margin: 8px; */
}

.megaMenuTitle {
  display: flex !important;
  flex-direction: row !important;
  width: 236px;
  height: 24px;
  padding: 0px;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.megaMenuPcontainer {
  width: 204px;
  height: 18px;
  margin: 3px 0px 11px 0px;
  display: flex;
  justify-content: flex-start;
}

.megaMenuP {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}

#lineNav {
  padding: 0px;
  margin: 0px;
  color: #fff;
}

.navBarTitle {
  height: 20px;
  color: #fff;
  text-decoration: none;
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  display: inline;
}

.navBarTitle:hover {
  color: white;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* .triangle {
  display:none !important;
  width: 30px ;
  height: 15px ;
  display: flex ;
  align-items: flex-start ;
  margin: 0px !important;
} */
