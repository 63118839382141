.columnaizq__container_main {
  width: 360px;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  position: relative;
  grid-area: columnIzq;
}
.containerColumn {
  width: 100%;
}
.columnaizq__container_content {
  width: 312px;
  margin: 40px 24px;
  text-align: left;
  font-family: Merriweather Sans;
}

.columnaizq__title_secciones {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.32px;
  font-weight: 700;
  color: #002856;
  margin-bottom: 32px;
}
.columnaizq__subTitle {
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 12px;
  margin-left: 12px;
  color: #002856;
}
.divisor {
  background-color: rgba(17, 88, 150, 0.15);
  height: 2px;
  width: 312px;
  margin: 0;
}
.columnaizq__sections_container {
  padding: 0;
}

.columnaizq__sections_container li {
  list-style: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #115896;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-radius: 6px;
  cursor: pointer;
  margin: 4px 0px;
}
.columnaizq__sections_container li:hover {
  background: rgba(17, 88, 150, 0.1);
}

.columnaizq__sections_container li:active {
  background: rgba(17, 88, 150, 0.15);
}

.active {
  background-color: #115896 !important;
  color: #fff !important;
}

.close__button {
  display: none;
}

/* Media query para tablet grande */
@media screen and (max-width: 1175px) {
  .columnaizq__container_main {
    box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
    transition: all 0.5s ease-in-out;
    position: relative;
    right: 180px;
    z-index: 1;
  }
  .columnaizq__noshow {
    transform: translateX(150vw);
  }
  .close__button {
    display: block;
    position: absolute;
    top: 22px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .columnaizq__container_main {
    right: 160px;
    width: 320px;
    border-radius: 0;
  }
  .columnaizq__container_content {
    width: 100%;
    margin: 0 !important;
    margin-left: 32px;
  }
  .columnaizq__title_secciones {
    padding: 16px;
    box-shadow: 0px 4px 5px 0px rgba(17, 88, 150, 0.1);
    margin-bottom: 16px;
  }
  .columnaizq__sections_container {
    margin: 0 16px;
  }
  /* .columnaizq__subTitle {
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 12px;
    margin-left: 12px;
    color: #002856;
  }
  .divisor {
    background-color: rgba(17, 88, 150, 0.15);
    height: 2px;
    width: 312px;
    margin: 0 12px;
  } */
}
