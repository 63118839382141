.container__form {
    display: grid;
    width: 100%;
    text-align: left;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(10, auto) 32px;
    grid-template-areas:
      "nombre apellido"
      "telefono dni"
      "email  email"
      "representante representante"
      "fecha hora"
      "sector sector"
      "lugar lugar"
      "temas temas"
      "vivencia vivencia"
      "pedidos pedidos"
      "loading loading"
      "btn btn";
    gap: 24px;
  }
  
  .title__form{
    text-align: left;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.32px;
    font-weight: 700;
    font-family: Merriweather Sans;
    color: var(--main-blue-color);
    border-bottom: 1px solid rgba(17, 88, 150, 0.15);
    padding-bottom: 8px;
    margin: 24px 0 32px 0;
  }
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .container__label_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
    position: relative;
  }
  .container__label_input label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-family: Merriweather Sans;
    color: var(--main-light-blue-color);
  }
  .container__label_input input,
  .container__label_input textarea,
  .container__label_input select {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.32px;
    font-family: Merriweather Sans;
    color: var(--main-blue-color);
    background-color: var(--main-hover-default-color);
    border-radius: 6px;
    margin: 0;
    padding: 8px 0 6px 16px;
    border: none !important;
    outline: none !important;
    width: 100%;
    resize: none;
  }
  
  .container__label_input input:hover,
  .container__label_input textarea:hover,
  .container__label_input select:hover {
    background-color: var(--main-hover-default-color);
  }
  .container__label_input input:focus,
  .container__label_input textarea:focus,
  .container__label_input select:focus {
    background-color: var(--main-hover-default-color);
    outline: 1px solid rgba(17, 88, 150, 0.5) !important;
  }
  .container__telefono_input {
    display: flex;
    gap: 8px;
  }
  .telefono__prefijo {
    width: 30% !important;
  }
  .telefono__numero {
    width: 70% !important;
  }
  .container__nombre {
    grid-area: nombre;
  }
  .container__apellido {
    grid-area: apellido;
  }
  
  .container__dni {
    grid-area: dni;
  }
  .container__email {
    grid-area: email;
  }
  
  .container__representante {
    grid-area: representante;
  }
  
  .container__telefono {
    grid-area: telefono;
  }
  .container__fechaDelSuceso {
    grid-area: fecha;
  }

  .container__horaDelSuceso {
    grid-area: hora;
  }

  .container__lugar{
    grid-area: lugar; 
    position: relative;
  }

  .container__lugar select{
    appearance: none;
  }

  .flecha_select{
    position: absolute;
    right: 12px;
    bottom: 7px;
  }

  .container__fechaDelSuceso input::-webkit-inner-spin-button,
  ::-webkit-calendar-picker-indicator {
    filter: brightness(0) saturate(100%) invert(15%) sepia(76%) saturate(3805%)
      hue-rotate(196deg) brightness(93%) contrast(87%);
    margin-right: 11px;
  }
  .container__vivencia {
    height: 150px;
    grid-area: vivencia;
  }
  .container__temas {
    grid-area: temas;
  }

  .container__label_checkbox{
    display: flex ;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin: 2px 0;
  }

  .container__label_checkbox input[type="checkbox"]{
    width: fit-content !important;
    display: none;
  }
 


  .container__label_checkbox input[type="checkbox"] + label:before {
    content: "";
    width: 16px;
    height: 16px;
    float: left;
    margin-top: 4px;
    margin-right: 8px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 2px;
    position: relative;
  }
  .container__label_checkbox input[type="checkbox"]:checked + label:before {
      border-color: var(--main-blue-color);
      background-color: var(--main-blue-color);
  }

  .container__label_checkbox input[type="checkbox"]:checked + label::after {
    content: "";
    width: 12px;
    height: 6px;
    border: 2px solid #fff;
    position: absolute;
    left: 2px;
    bottom: 10px;
    border-right: 0;
    border-top: 0;
    margin-top: 1em;
    transform: rotate(-55deg);
  }

  .container__label_checkbox input[type="checkbox"] + label{
    color: var(--main-blue-color) !important;
  }

  .title_cb{
    margin-bottom: 8px;
  }

  .especial__cb:checked + label::after {
    content: "";
    width: 12px;
    height: 6px;
    border: 2px solid #fff;
    position: absolute;
    left: 2px;
    bottom: 34px !important;
    border-right: 0;
    border-top: 0;
    margin-top: 1em;
    transform: rotate(-55deg);
  }

  .container__input_otra{
    margin-top: 8px !important;
  }

  .container__pedidos {
    grid-area: pedidos;
    position: relative;
  }

  .container__sector{
    grid-area: sector;
  }

  .container__pedidos input {
    display: none;
  }
  
  .btn__enviar {
    grid-area: btn;
    justify-self: center;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.32px;
    font-weight: 400;
    font-family: Merriweather Sans;
    color: var(--main-blue-color);
    background-color: var(--main-yellow-color);
    width: 59px;
    height: 25px;
    border: none;
    border-radius: 4px;
  }
  .disabled {
    opacity: 0.3;
  }
  
  .buttonConfirm {
    width: 240px;
    height: 32px;
    background-color: var(--main-blue-color);
    color: #fff;
    align-self: center;
    justify-self: center;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.32px;
    font-family: Merriweather Sans;
    border-radius: 4px;
    margin: 0;
    margin-bottom: 22px;
  }
  
  .titleAlert {
    color: var(--main-blue-color) !important;
    font-family: Merriweather Sans !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: -0.32px !important;
    width: 240px;
    justify-self: center;
    padding: 0 !important;
    margin: 0 auto !important;
    margin-top: 19px !important;
  }
  
  .containerAlert {
    width: 386px !important;
    height: 194px;
    display: flex !important;
    justify-content: space-around;
    flex-direction: column;
    position: static !important;
    box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5) !important;
    padding: 0 !important;
  }
  
  .containerHtml {
    margin: 0 !important;
    overflow: hidden !important;
    margin-top: 12px !important;
  }
  
  .error__message {
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: -0.32px;
    font-family: Merriweather Sans;
    color: #ff4949;
    position: absolute;
    bottom: -20px;
  }
  .not__empty {
    background-color: var(--main-hover-default-color) !important;
  }

  .label__custom {
    background-color: var(--main-light-blue-color);
    width: 112px;
    height: 32px;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: -0.32px !important;
    line-height: 15px !important;
    font-family: Merriweather Sans !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8.5px 12px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
  }
  
  .label__formatos {
    color: #575757 !important;
  }
  
  .archivoCargado__btn {
    margin-right: 8px;
  }
  
  .archivoCargado__btn_close {
    cursor: pointer;
    margin-left: 8px;
  }
  
  .container__label_archivoCargado {
    display: flex;
    align-items: center;
  }

  .cancelAlert {
    width: 386px;
    height: 148px;
    box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5) !important;
  }

  .eliminar_btn {
    width: 108px;
    height: 32px;
    background-color: var(--main-blue-color);
    border: 1px solid #002856;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.32px;
    font-weight: 400;
    font-family: Merriweather Sans;
    margin-left: 24px;
  }

  .error__message__pedidosMedicos {
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: -0.32px;
    font-family: Merriweather Sans;
    color: #ff4949;
    position: absolute;
    bottom: -16px;
  }

  .no_btn {
    width: 108px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #002856;
    border-radius: 4px;
    color: var(--main-blue-color);
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.32px;
    font-weight: 400;
    font-family: Merriweather Sans;
  }

  .container__radio{
      width: 100%;
      display: flex;
      align-items: center;
  }

  .container__radio input{
      width: fit-content;
      margin-left: 16px;
  }

  .loadingContainer {
    grid-area: loading;
    height: auto;
    display: flex;
    justify-content: center;
  }

  /* Media query para tablet chica */
  @media screen and (max-width: 791px) {
    .container__form {
      grid-template-columns: 244px 244px;
    }
    /* .telefono__numero {
      width: 70% !important;
    } */
  }
  
  /* Media query para movil */
  @media screen and (max-width: 607px) {
    .container__form {
      grid-template-columns: 100%;
      grid-template-rows: repeat(13, auto) 32px;
      /* grid-template-areas:
      "nombre apellido"
      "telefono dni"
      "email  email"
      "representante representante"
      "fecha hora"
      "sector sector"
      "lugar lugar"
      "temas temas"
      "vivencia vivencia"
      "pedidos pedidos"
      "btn btn"; */
      grid-template-areas:
        "nombre"
        "apellido"
        "telefono"
        "dni"
        "email"
        "representante"
        "fecha"
        "hora"
        "sector"
        "lugar"
        "temas"
        "vivencia"
        "pedidos"
        "btn";
    }
    /* .telefono__numero {
      width: 70% !important;
    }
    .telefono__prefijo{
      width: 30% !important;
    } */
    .container__telefono_input {
      width: 100%;
    }
    .containerAlert {
      width: 272px !important;
    }
  }
  