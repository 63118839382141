.backofficeNavImage-container {
    width: 100%;
    background: #FFF;
    border-bottom: 1px solid rgb(187, 186, 186);
    /* background: #002856; */
    display: flex  ;
    justify-content: center;
    align-items: center !important;
    margin: 0px;
    height: 50px;
    padding: 0px;
    /* justify-content: space-evenly; */
}

.backofficeNavImage-ul {
    display: flex;
    justify-content: space-evenly;
    background: #002856;
    height: 100%;
    width: 90%;
    align-items: center;
    /* border: solid red 1px; */
}

.backofficeNavImage-li {
    display: flex;
    align-items: center;
}

.backofficeNavImage-a {
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}