.BodyHomeContainer {
  width: 100%;
  overflow: hidden;
  margin-top: 154px;
  overflow: hidden;
  /* position: relative; */
}
a {
  text-decoration: none !important;
}
.Carousel {
  width: 100%;
  height: fit-content;
  overflow: hidden;
}

#line {
  height: 193px;
  width: 73px;
}
.cardsHomeContainer {
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  top: 508px;
  margin-left: auto;
  margin-right: auto; 
}

/* .container__caritas__mensaje {
  border: solid red 1px;
} */

.cardsHomeContainerSub {
  width: 744px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 24px;
}

.cardsHome {
  width: 168px;
  height: 168px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(17, 88, 150, 0.75);
  box-sizing: border-box;
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  border-radius: 6px;
  transition: 300ms all ease;
}
.cardsHome:hover {
  transform: scale(1.1);
  box-shadow: 0px 9px 15px rgba(17, 88, 150, 0.5);
}

.linkCard {
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.cardsHome Link {
  display: flex;
  flex-direction: column;
}

.cardsHome img {
  width: 80px;
  height: 80px;
}

.cardsHome a {
  margin-top: 10px;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.18px;
  color: #aa7901;
  text-decoration: none;
  transition: ease 300ms all;
}
.cardsHome:hover a {
  color: #115896;
}

.cardsHome1 {
  width: 168px;
  height: 108px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(17, 88, 150, 0.75);
  box-sizing: border-box;
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  border-radius: 6px;
  transition: ease 300ms all;
}
.cardsHome1:hover {
  transform: translateY(-10px);
}
.cardsHome1:hover span {
  color: #115896;
}

.cardsHome1 img {
  width: 40px;
  height: 40px;
}

.cardsHome1 span {
  margin-top: 10px;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.18px;
  color: #aa7901;
  text-decoration: none;
  transition: ease 300ms all;
}

.dataTitleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* margin-top: 42px; */
  z-index: 2;
}

.dataTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-bottom: 46px;
}

#dataTitle1 {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.18px;
  color: #115896;
  margin-bottom: 6px;
}

#dataTitle2 {
  font-family: Merriweather;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #002856;
}

.frameContainerGral {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: 16px;
}

.frameContainer {
  min-width: 1673px;
  height: 137.7px;
  display: flex;
  justify-content: space-between;
  z-index: 0;
}

.frame2ContainerGral {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 85px;
  z-index: 0;
  margin-bottom: -42px;
}

.frame2Container {
  min-width: 1280px;
  display: flex;
  justify-content: space-between;
}

#frame4 {
  display: none;
}

.statistics {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-card {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.health {
  /* position: absolute; */
  top: 1313px;
  width: 100%;
  height: 416px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0px 50px 0px 30px;
  background: #fcefde;
  background-image: url("./Baner1176.svg");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  z-index: 1;
}

.healthContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1030px;
  height: 236px;
  z-index: 1;
}

.healthContainer1 {
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 361px;
  margin-right: 0;
}

#health1 {
  display: flex;
  justify-content: flex-start;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.18px;
  color: #115896;
  text-decoration: none;
  width: 200px;
  margin-bottom: 8px;
}

.health2 {
  width: 296px;
  padding: 0px;
  text-align: start;
}

.health2 a {
  font-family: Merriweather;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 45px;
  color: #002856;
  padding: 0px;
  text-decoration: none;
}
.health2 a:hover {
  color: #002856;
}

.health3 {
  margin-top: 8px;
  text-align: start;
  width: 361px;
}

.health3 a {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #002856;
  text-decoration: none;
}
.health3 a:hover {
  color: #002856;
}

#healthButton {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e2b900;
  border-radius: 4px;
  border: none;
  width: 146px;
  height: 40px;
  color: #002856;
  padding: 7px 12px;
  font-size: 16px;
  font-family: "Merriweather Sans";
  font-weight: 400;
}
#healthButton:hover {
  background: #e6c121;
  color: #073a74;
}
#healthButton2 {
  margin-top: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #e2b900;
  border-radius: 4px;
  border: none;
  width: 135px;
  height: 40px;
  color: #002856;
  font-size: 16px;
  font-family: "Merriweather Sans";
  font-weight: 400;
}

.carouselNewsSection {
  margin: 89px;
  width: 600px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px 0px 0px 0px;
  position: relative;
  z-index: 1;
}

.formContainer1 {
  width: auto;
  height: 1065.62px;
  position: absolute;
  bottom: 20px;
  right: 1500px;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 0;
  visibility: visible;
}

.formContainer2 {
  position: absolute;
  width: auto;
  height: auto;
  bottom: 55px;
  right: 1590px;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.formContainer3 {
  position: absolute;
  width: 1126px;
  height: 688.67px;
  top: -60px;
  left: 1600px;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.formContainer4 {
  position: absolute;
  width: 1006px;
  height: 615px;
  top: 269px;
  left: 1550px;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
#form1 {
  transform: matrix(0.9, -0.44, 0.44, 0.9, 0, 0);
  position: relative;
  width: auto;
  height: auto;
  z-index: 0;
}

#form2 {
  position: relative;
  width: 1006px;
  height: 615px;
  border: 1px solid #115896;
  z-index: 0;
}

#form3 {
  transform: matrix(0.9, -0.44, 0.44, 0.9, 0, 0);
  position: relative;
  width: auto;
  height: auto;
  z-index: 0;
}

#form4 {
  transform: matrix(0.9, -0.44, 0.44, 0.9, 0, 0);
  position: relative;
  width: auto;
  height: auto;
  z-index: 0;
}

#messages {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 190px;
  right: 0px;
  z-index: 2;
  background: #e2b900;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.chat {
  width: 32px !important;
  height: 32px !important;
}

#default-emoticon {
  width: 32px;
  height: 32px;
  filter: brightness(0) saturate(100%) invert(14%) sepia(72%) saturate(1510%)
    hue-rotate(189deg) brightness(89%) contrast(108%);
}

.happyFace{
  width: 36px;
  height: 36px;
}
.normalFace{
  width: 36px;
  height: 36px;
}
.sadFace{
  width: 36px;
  height: 36px;
}

/* .containerRaitingComponent {
  position: absolute;
  top: 254px;
  right: 0px;
  z-index: 203 !important;
  -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  border: 1px solid red;
} */

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/*=============== MEDIA QUERIES ===============*/
/* For small devices */

/* Medias para Tablet */
@media only screen and (max-width: 1432px) {
  .frameContainer {
    min-width: 1508px;
  }
}

@media only screen and (max-width: 1176px) {
  .frameContainer {
    min-width: 1350px;
  }
}

@media only screen and (max-width: 1175px) {
  #messages {
    display: none;
  }
  #emoji {
    width: 54px;
    height: 51px;
    position: absolute;
    top: 200px;
    right: 0px;
    z-index: 2;
    background: #ffffff;
    border-radius: 6px 0px 0px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerRaitingComponent {
    display: none;
  }
  .health {
    background-image: url(./Baner791.svg);
  }
  .healthContainer {
    margin-left: 30px;
  }
  .healthContainer1 {
    width: 278px;
    margin-top: 10px;
  }
  .carouselNewsSection {
    width: 450px;
  }
  #health1 {
    font-size: 16px;
  }
  .health2 {
    width: 240px;
  }
  .health2 a {
    font-size: 36px;
    line-height: 38px;
  }
  .health3 {
    width: 278px;
  }
  .health3 a {
    font-size: 16px;
    line-height: 15.08px;
  }
  .cardsHomeContainerSub {
    width: 744px;
  }

  .img-card {
    width: 48px;
    height: 48px;
  }

  #frame4 {
    display: block;
    width: 95px;
    height: 84px;
  }
  #frame3 {
    width: 95px;
    height: 84px;
  }

  .frameContainerGral {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: 16px;
    position: absolute;
  }

  .frame2ContainerGral {
    bottom: 50px;
    margin-bottom: -15px;
  }

  .frame2Container {
    min-width: 823px;
  }

  #dataTitle1 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  #dataTitle2 {
    font-size: 24px;
    line-height: 30px;
  }

  .dataTitleContainer {
    margin-top: 110px;
  }
  .cardsHomeContainer {
    top: 434px;
  }
}
@media only screen and (max-width: 791px) {
  .statisticsContainer {
    width: 560px;
  }
  .cardsHomeContainer {
    top: 355px;
  }

  .cardsHomeContainerSub {
    width: 560px;
    min-width: auto;
  }
  .cardsHome,
  .cardsHome1 {
    width: 128px;
    height: 128px;
  }
  .cardsHome img,
  .cardsHome1 img {
    width: 60px;
    height: 60px;
  }
  .cardsHome a,
  .cardsHome1 span {
    font-size: 12px;
    line-height: 14px;
  }
  .health {
    background-image: url(./Baner607.svg);
    height: 531px;
    align-items: flex-start;
    padding: 150px 0 0 0;
    justify-content: center;
  }
  .healthContainer {
    flex-direction: column;
    width: 550px;
  }
  #health1 {
    font-size: 16px;
    width: auto;
    align-self: start;
    margin-left: 70px;
  }
  .health2 {
    width: 416px;
  }
  .health2 a {
    font-size: 32px;
    font-weight: 700;
  }
  .health3 {
    width: 416px;
  }

  .healthContainer1 {
    width: auto;
    margin-bottom: 11px;
    align-items: center;
    margin-left: -10px;
  }
  #healthButton {
    display: none;
  }
  #healthButton2 {
    margin-top: 32px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e2b900;
    border-radius: 4px;
    border: none;
    width: 225px;
    height: 32px;
    padding: 8.5px;
    margin-bottom: 20px;
  }
  .frame2Container {
    min-width: 560px;
  }
  .frame2ContainerGral {
    overflow: hidden;
    bottom: 250px;
    margin-bottom: -36px;
  }
  .dataTitleContainer {
    /* margin-top: 530px; */
  }

  .dataTitle {
    position: relative;
    top: 18px;
  }

  #emoji {
    width: 48px;
    height: 48px;
    position: absolute;
    right: 0px;
    z-index: 2;
    background: #ffffff;
    border-radius: 6px 0px 0px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerRaitingComponent {
    display: none;
  }
}

@media only screen and (max-width: 607px) {

  .BodyHomeContainer {
    width: 100%;
    overflow: hidden;
    margin-top: 112px;
    /* position: relative; */
  }

  #dataTitle1 {
    margin: 0;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
  }
  .frame2Container {
    min-width: 407px;
  }
  .frame2ContainerGral {
    margin-bottom: -84px;
    bottom: 235px;
  }
  .dataTitle {
    width: 240px;
  }
  .cardsHomeContainer {
    min-width: 315px;
    top: 390px;
  }
  .cardsHomeContainerSub {
    width: 272px;
    flex-wrap: wrap;
    min-width: 272px;
  }

  .cardsHome1,
  .cardsHome2 {
    margin-top: 16px;
  }
  .health {
    background-image: url(./Baner320.svg);
    height: 691px;
    align-items: flex-start;
    padding: 150px 0 0 0;
    justify-content: center;
  }
  .healthContainer {
    align-items: center;
    width: 280px;
    margin: 0;
  }
  .healthContainer1 {
    width: 254px;
    margin: 130px 0px 16px 0px;
  }
  #health1 {
    font-size: 16px;
    width: 254px;
    align-self: start;
    margin: 32px 0px 8px 0px;
  }
  .health2 {
    width: 254px;
    align-self: start;
    margin-bottom: 8px;
  }
  .health3 {
    width: 254px;
    align-self: start;
    margin: 0;
  }
  .carouselNewsSection {
    width: auto;
  }
  #healthButton2 {
    margin-top: 140px;
    padding: 8.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e2b900;
    border-radius: 4px;
    border: none;
    width: 110px;
    height: 32px;
    font-size: 12px;
  }
  #frame2 {
    display: none;
  }

  .frameContainer {
    min-width: 50px;
  }

  .frameContainerGral {
    margin-top: 45px;
  }

  #frame1 {
    width: 85px;
    height: 75px;
  }

  .dataTitleContainer {
    margin-top: 248px;
    /* border: solid greenyellow 3px; */
  }

  .containerRaitingComponent {
    display: none;
  }
  #emoji {
    top: 175px;
  }
}

@media only screen and (max-width: 319px) {
  .containerRaitingComponent {
    display: none;
  }
}
