iframe {
  width: 100%;
  height: 284px;
}

.container {
  background-color: #e5e5e5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.father {
  position: relative;
  width: fit-content;
  display: grid;
  grid-template-columns: 360px 744px;
  grid-template-areas: "columnIzq columnDer";
  grid-template-rows: auto;
  justify-items: center;
  margin: 0 auto;
  margin-bottom: 56px;
  column-gap: 24px;
}
.container__flecha_home {
  width: 1128px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 24px;
}

.container__flecha_home_content {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.container__flecha_home_content span {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.32px;
  color: #115896;
  font-family: Merriweather Sans;
}

.flecha__home {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.columnader__container_main {
  width: 744px;
  padding: 40px;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  grid-area: columnDer;
}
.columnader__container_content {
  width: 100%;
  text-align: left;
  position: relative;
}
.columnaizq__container_main {
  width: 360px;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  grid-area: columnIzq;
}
.columnaizq__container_content {
  width: 312px;
  margin: 40px 24px;
  text-align: left;
  font-family: Merriweather Sans;
}
.sections__button {
  display: none;
}
.columnaizq__title {
  font-size: 24px;
  font-family: Merriweather Sans;
  font-weight: 700;
  color: #002856;
  margin: 40px 24px;
  width: 100%;
  height: max-content;
  text-align: start;
}
.columnaizq__container_sites {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 32px;
}
.columnaizq__container_barrio {
  display: flex;
  margin-left: 27px;
  align-items: center;
}
.name_barrio {
  margin-bottom: 0px;
  margin-left: 11px;
  font-size: 18px;
  color: #002856;
  font-family: Merriweather Sans;
  font-weight: bold;
}
.division {
  height: 1px;
  width: 312px;
  background-color: rgba(17, 88, 150, 0.15);
  margin: 12px 24px 4px 24px;
}
.columnaizq__btn {
  width: 312px;
  height: 48px;
  margin: 4px 24px;
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  padding: 12px;
  color: #115896;
  font-family: Merriweather Sans;
  font-weight: bold;
  background-color: transparent;
  border-radius: 6px;
  text-align: start;
}
.columnaizq__btn:hover {
  background: rgba(17, 88, 150, 0.1);
}
.select {
  width: 312px;
  height: 48px;
  margin: 4px 24px;
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  padding: 12px;
  font-family: Merriweather Sans;
  font-weight: bold;
  background-color: transparent;
  border-radius: 6px;
  background-color: #115896;
  color: #fff;
  text-align: start;
}
.edificio {
  margin-right: -3px;
}
.barrio {
  height: 24px;
  width: 24px;
}
.columnader__banner {
  width: 100%;
  height: 256px;
  background-color: #7aaedb;
  border-radius: 6px;
  background-position: center center;
  background-size: cover;
}
.columnader__banner_name {
  background-color: rgba(0, 40, 86, 0.75);
  height: 68px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 24px 14px;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  border-radius: 6px 6px 0 0;
  position: absolute;
  width: 100%;
  top: 0
}
.columnader__title {
  font-size: 24px;
  color: #115896;
  font-weight: 700;
  font-family: Merriweather Sans;
  margin-top: 24px;
}

.columnader__title_sede {
  font-size: 24px;
  color: #115896;
  font-weight: 700;
  font-family: Merriweather Sans;
}

.columnader__txt {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.division2 {
  height: 1px;
  width: 100%;
  background-color: rgba(17, 88, 150, 0.15);
  margin: 8px 0px 16px 0px;
}
.columnader__subtitulo {
  color: #002856;
  font-size: 18px;
  font-weight: 700;
  font-family: Merriweather Sans;
}
.columnader__info {
  font-size: 14px;
  font-weight: 400;
  color: #115896;
  font-family: Merriweather Sans;
}

.close {
  display: none;
}

@media screen and (max-width: 1175px) {
  .container__flecha_home {
    width: 744px;
  }
  .father {
    grid-template-columns: auto 0px;
    gap: 0px;
    grid-template-areas: "columnDer columnIzq";
  }
  .columnaizq__container_main {
    box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
    transition: all 0.5s ease-in-out;
    position: absolute;
    right: 0px;
    z-index: 1;
    width: 320px;
  }
  .division {
    width: 272px;
  }
  .columnaizq__noshow {
    transform: translateX(150vw);
  }
  .close__button {
    display: block;
    position: absolute;
    top: 22px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .columnaizq__btn {
    width: 272px;
  }
  .select {
    width: 272px;
  }
  .sections__button {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
    filter: brightness(0) saturate(100%) invert(93%) sepia(94%) saturate(0%)
      hue-rotate(248deg) brightness(106%) contrast(106%);
  }
  .close {
    display: flex;
    position: absolute;
    top: 42px;
    right: 20px;
    cursor: pointer;
  }
  .columnader__banner_name {
    padding: 24px 50px 24px 14px;
  }
}
@media screen and (max-width: 791px) {
  .container__flecha_home {
    width: 560px;
    height: 24px;
    margin-bottom: 8px;
  }
  .container__flecha_home_content span {
    font-size: 12px;
    line-height: 15px;
  }
  .flecha__home {
    width: 12px;
    height: 12px;
  }
  .columnader__container_main {
    width: 560px;
    height: auto;
    margin: 0;
    padding: 40px 24px;
  }
  .columnader__container_content {
    width: 100%;
    margin: 0px;
  }
  .columnader__banner {
    width: 100%;
    height: 198px;
    background-color: #7aaedb;
    border-radius: 6px;
  }
  .columnader__banner_name {
    background-color: rgba(0, 40, 86, 0.75);
    height: 60px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 24px 40px 24px 14px;
    font-family: Merriweather Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    border-radius: 6px 6px 0 0;
  }
}
/* Media query para movil */
@media screen and (max-width: 607px) {
  .father {
    margin: 0;
    width: 100%;
    justify-content: initial;
  }
  .container__flecha_home {
    top: 320px;
    left: 24px;
    position: absolute;
    z-index: 1;
  }
  .columnaizq__container_main {
    border-radius: 0;
  }
  .columnaizq__container_content {
    width: 100%;
    margin: 0 !important;
    margin-left: 32px;
  }

  .columnader__container_content {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0px;
  }

  .columnader__container_main {
    width: 100%;
    height: auto;
  }
  .columnaizq__title {
    margin: 12px 0px 28px 24px;
  }
  .close {
    top: 12px;
  }
}
