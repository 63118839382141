.container {
    width: 100%;
    height: 100%;
}

.create {
    width: 100%;
    height: 60px;
    padding: 10px 20px 10px 20px;
    display: flex;
    justify-content: flex-start;
}

.createButton {
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #002856;
    color: white;
    cursor: pointer;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #002856;
    margin-right: 20px;
    font-size: 12px;
}

.gridContainer {
    margin-top: 20px;
}

.paramImg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .paramImg img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  


.numberContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}