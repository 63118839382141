.newsContainer {
    width: 100%;
}

.checkContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.checkIcon {
    color: teal;
}

.cancelIcon {
    color: red;
}

.createAndFilter {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
}

.selectSpecialty {
    border: 1px solid lightgray;
    padding: 5px;
    margin: 15px;
    border-radius: 4px;
}

.selectArea {
    border: none;
    margin:15px;
}

.datagridContainer {
    width: 100%;
    height: auto;
    scrollbar-width: none;
    margin: 0;
    padding: 0;
}

.newButtonCreate {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: #002856;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

.newButtonActive {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

.newButtonActiveOff {
    width: 100px;
    border: none;
    padding: 5px;
    background-color: red;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}