.container__main {
  background-color: #fff;
  border-radius: 6px;
  grid-area: columnDer;
  height: 100%;
}

.container__content {
  width: 312px;
  margin: 40px 24px;
  position: relative;
  background-color: #fff;
}

.container__nuestrasnotas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container__nuestrasnotas h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  color: #002856;
  margin-bottom: 32px;
}

.container__sections {
  padding: 0;
  margin: 0;
  width: 100%;
}

.container__sections li {
  list-style: none;
  text-align: left;
  padding: 12px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  border-radius: 6px;
  color: #115896;
  cursor: pointer;
  width: 100%;
}

.container__notas_recomendadas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 28px;
}

.container__notas_recomendadas h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #002856;
  padding: 12px 0;
  border-bottom: 1px solid rgba(17, 88, 150, 0.15);
  width: 100%;
  text-align: left;
  font-family: Merriweather Sans;
  margin-bottom: 4px;
}

.container__recomendadas {
  padding: 0;
  margin: 0;
  width: 100%;
}

.container__recomendadas li {
  list-style: none;
  text-align: left;
  padding: 12px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  font-family: Merriweather;
  border-radius: 6px;
  color: #115896;
  cursor: pointer;
}

.container__recomendadas li:hover {
  font-weight: 700 !important;
  color: #002856 !important;
  background-color: rgba(17, 88, 150, 0.05);
}

.close_btn {
  display: none;
}

.container__sections li:hover {
  background: rgba(17, 88, 150, 0.1);
}

.container__sections li:active {
  background: rgba(17, 88, 150, 0.15);
}

.selected {
  background-color: #115896 !important;
  color: #fff !important;
}

/* Media query para tablet grande */
@media screen and (max-width: 1175px) {
  .container__main {
    position: relative;
    right: 180px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    transform: translateX(1000vw);
    box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  }
  .show_sections {
    transform: translateY(0);
  }
  .close_btn {
    display: block;
    position: absolute;
    right: 0;
    top: 4px;
    cursor: pointer;
  }
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  /* .container__main {
    right: 360px;
  } */
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__main {
    width: 320px;
    border-radius: 0;
    right: 160px;
  }

  .container__content {
    width: 88%;
  }

  .sombreado__nuestrasnotas {
    width: 100%;
    height: 2px;
    position: absolute;
    z-index: 1;
    top: 80px;
  }
}
