.footer__container_main {
  background: #002856;
  width: 100%;
  min-width: 320px;
  height: 403px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0; 
}

.footer__container_content {
  width: 1128px;
  margin: 30px 24px;
  height: 343px;
  display: grid;
  grid-template-columns: auto auto 1fr 318px auto;
  grid-template-rows: repeat(2, auto);
  justify-content: space-between;
  row-gap: 50px;
  grid-template-areas:
    "nosacompaña sedes contacto contacto acreditaciones"
    "redes line line derechos datafiscal";
  font-family: Merriweather;
}

.footer__container_content_one_logo_nosacompaña {
  display: flex;
  flex-direction: column;
  grid-area: nosacompaña;
  width: 170px;
  height: 100%;
  justify-content: space-between;
}

.footer__container_content_one_sedes {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-area: sedes;
  width: 193px;
  height: 250px;
}

.footer__container_content_one_nosacompaña_img {
  display: flex;
  flex-direction: column;
}

.footer__container_content_one_contacto_trabaja_sugerencias {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: start;
  grid-area: contacto;
  width: fit-content;
  height: 191px;
  position: relative;
  left: 110px;
}

.footer__container_content_one_nosacompaña_img {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.footer__container_content_one_acreditaciones {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-area: acreditaciones;
  width: 181px;
  height: 122px;
}

.footer__logo {
  width: 170px;
  height: 48px;
}

.footer__container_content_one_nosacompaña {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 150px;
}

.footer__container_content_one_nosacompaña span {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-top: 34px;
  line-height: 25px;
}

.footer__ucc {
  margin: 16px 0;
  margin-left: 0px;
  height: 40px;
  width: auto;
}

.footer__jesuitas {
  margin-left: 0px;
  height: 40px;
  width: auto;
}

.footer__container_content_one_sedes span {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}

.footer__container_barrio_gp {
  margin-top: 16px;
  font-family: Merriweather Sans;
}

.footer__ubicacion {
  width: 20px;
  height: 20px;
}

.footer__li {
  color: #fff;
}

.footer__li:hover {
  color: #e2b900;
}

.footer__container_barrio_gp_img_span {
  display: flex;
  justify-content: flex-start;
}

.footer__container_barrio_gp_img_span span {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-left: 6px;
}

.footer__ul_barrio_gp {
  list-style: none;
  padding: 0;
  text-align: left;
}

.footer__ul_barrio_gp li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 26px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.footer_container_barrio_jardin {
  margin-top: 8px;
  font-family: Merriweather Sans;
}

.footer__container_barrio_jardin_img_span span {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-left: 6px;
}

.footer__container_barrio_jardin_img_span {
  display: flex;
  justify-content: flex-start;
}

.footer__ul_barrio_jardin {
  padding: 0;
  list-style: none;
  text-align: left;
}

.footer__ul_barrio_jardin li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 26px;
  margin-top: 8px;
}

.footer__container_content_one_contacto_trabaja_sugerencias span {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
  margin-bottom: 16px;
}
.footer__linkedin:hover {
  filter: brightness(0) saturate(100%) invert(74%) sepia(11%) saturate(4798%)
    hue-rotate(8deg) brightness(96%) contrast(105%);
}

.footer__email,
.footer__telefono {
  width: 20px;
  height: 20px;
}

.footer__container_content_one_contacto {
  font-family: Merriweather Sans;
}

.footer__container_contacto_telefono {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
}

.footer__container_contacto_telefono a {
  text-decoration: none;
}

.footer__container_contacto_telefono a span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 6px;
}

.footer__container_contacto_telefono:hover > a span {
  color: #e2b900;
}

.footer__container_contacto_telefono:hover > img {
  filter: brightness(0) saturate(100%) invert(71%) sepia(66%) saturate(2259%)
    hue-rotate(9deg) brightness(100%) contrast(101%);
}

.footer__container_contacto_email {
  display: flex;
  align-items: center;
}

.footer__container_contacto_email a {
  text-decoration: none;
}

.footer__container_contacto_email a span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  margin-left: 6px;
}

.footer__container_contacto_email:hover > a span {
  color: #e2b900;
}

.footer__container_contacto_email:hover > img {
  filter: brightness(0) saturate(100%) invert(71%) sepia(66%) saturate(2259%)
    hue-rotate(9deg) brightness(100%) contrast(101%);
}

.footer__container_content_one_trabaja {
  margin-top: 42px;
  margin-bottom: 16px;
}

.footer__container_content_one_trabaja span:hover {
  color: #e2b900;
}

.footer__container_content_one_sugerencias span:hover {
  color: #e2b900;
  cursor: pointer;
}

.footer__container_content_one_acreditaciones span {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
  margin-bottom: 16px;
}

.footer__container_content_one_acreditaciones_images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer__gestionCalidad {
  width: 48px;
  height: 83px;
}

.footer__iqnet {
  width: 40px;
  height: 40px;
}

.footer__itaes {
  width: 60px;
  height: 60px;
}

.footer__container_content_two_redes {
  width: 264px;
  height: 52px;
  border-radius: 4px;
  background-color: #115896;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  grid-area: redes;
}

.footer__container_content_two_redes span {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.footer__container_content_two_redes img {
  width: 24px;
  height: 24px;
}

.footer__container_content_two_redes img:hover {
  filter: brightness(0) saturate(100%) invert(65%) sepia(64%) saturate(928%)
    hue-rotate(9deg) brightness(100%) contrast(101%);
}

.footer__line {
  align-self: center;
  grid-area: line;
  position: relative;
  left: 10px;
  width: 368px;
  height: 1px;
  background-color: #fff;
  margin-top: 30px;
}

.footer__container_content_two_derechos {
  grid-area: derechos;
  align-self: center;
  position: relative;
  left: 20px;
  font-family: Merriweather Sans;
  margin-top: 30px; 
}

.footer__container_content_two_derechos span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 302px;
}

.footer__container_content_two_datafiscal_pdp {
  justify-self: end;
  width: 138px;
  grid-area: datafiscal;
}

.footer__container_datafiscal_content {
  display: flex;
  justify-content: space-between;
}

.footer__dataFiscal {
  width: 39px;
  height: 54px;
}

.footer__pdp {
  width: 83px;
  height: 54px;
}

/* Media querys para tablet grande */
@media screen and (max-width: 1175px) {
  .footer__container_main {
    height: 399px;
  }
  .footer__logo {
    display: none;
  }

  .footer__container_content_one_nosacompaña span,
  .footer__container_content_one_sedes span,
  .footer__container_content_one_contacto_trabaja_sugerencias span,
  .footer__container_content_one_acreditaciones span {
    font-size: 16px;
    line-height: 20px;
  }

  .footer__container_content_one_nosacompaña_img{
    height: 100px;
  }

  .footer__ubicacion,
  .footer__telefono,
  .footer__email {
    width: 16px;
    height: 16px;
  }

  .footer__container_content_one_nosacompaña span {
    margin: 0;
  }

  .footer__container_content_one_contacto span {
    font-size: 12px !important;
    line-height: 17px !important;
  }

  .footer__container_content_one_trabaja {
    margin: 10px 0;
  }

  .footer__dataFiscal {
    width: 29px;
    height: 40px;
  }

  .footer__pdp {
    width: 61px;
    height: 40px;
  }

  .footer__container_barrio_gp_img_span span,
  .footer__container_barrio_jardin_img_span span {
    font-size: 14px;
    line-height: 20px;
  }

  .footer__ul_barrio_gp li,
  .footer__ul_barrio_jardin li {
    margin-left: 22px;
  }

  .footer__gestionCalidad {
    width: 33px;
    height: 56px;
  }

  .footer__iqnet {
    width: 27px;
    height: 27px;
  }

  .footer__itaes {
    width: 32px;
    height: 32px;
  }

  .footer__container_content_one_acreditaciones_images {
    width: 124px;
  }

  .footer_container_barrio_jardin {
    margin: 0;
  }

  .footer__container_content {
    grid-template-columns: auto minmax(100px, 1fr) auto auto auto;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "sedes contacto nosacompaña  nosacompaña nosacompaña "
      "sedes datafiscal acreditaciones acreditaciones acreditaciones "
      "redes line line derechos derechos ";
    gap: 0;
    margin: 40px 24px;
    height: 310px;
    column-gap: 10px;
  }

  .footer__container_content_one_logo_nosacompaña {
    grid-area: nosacompaña;
    height: fit-content;
    width: fit-content;
    justify-self: start;
  }

  .footer__container_content_one_sedes {
    grid-area: sedes;
    height: fit-content;
    width: fit-content;
  }

  .footer__container_content_one_contacto_trabaja_sugerencias {
    position: static;
    grid-area: contacto;
    width: fit-content;
    height: fit-content;
    justify-self: center;
  }

  .footer__container_content_one_acreditaciones {
    grid-area: acreditaciones;
    height: fit-content;
    width: fit-content;
    position: relative;
    bottom: 15px;
    justify-self: start;
  }

  .footer__container_content_two_redes {
    grid-area: redes;
    margin-top: 10px;
  }

  .footer__line {
    grid-area: line;
    position: static;
    margin-top: 10px;
    width: auto;
    align-self: center;
  }

  .footer__container_content_two_derechos {
    grid-area: derechos;
    position: static;
    justify-self: center;
    margin-top: 10px;
    width: auto;
  }

  .footer__container_content_two_datafiscal_pdp {
    grid-area: datafiscal;
    width: 246px;
    height: fit-content;
    justify-self: center;
    align-self: center;
    position: relative;
    display: flex;
    justify-content: flex-start;
  }

  .footer__container_datafiscal_content {
    width: 118px;
    height: 40px;
    display: flex;
    justify-content: space-between;
  }

  .footer__container_content_one_sugerencias {
    text-align: left;
  }
}

/* Media query solo para ajustar tamaños */
@media screen and (max-width: 900px) {
  .footer__container_content_two_datafiscal_pdp {
    width: 228px;
  }

  .footer__container_content_one_logo_nosacompaña {
    justify-self: center;
  }

  .footer__container_content_one_acreditaciones {
    justify-self: center;
  }
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .footer__container_main {
    /* top: 1844px; */
    height: 629px;
  }

  .footer__container_content {
    margin: 32px 24px;
    height: 580px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas:
      "sedes contacto"
      "redes redes"
      "nosacompaña acreditaciones"
      "line1 line1"
      "datafiscal datafiscal"
      "line2 line2"
      "derechos derechos";

    gap: 0;
    row-gap: 24px;
  }

  .footer__line {
    background-color: #11589680;
    grid-area: line1;
    margin: 0;
  }

  .footer__line2 {
    background-color: #11589680;
    grid-area: line2;
    width: auto;
    height: 1px;
    align-self: center;
  }

  .footer__container_content_one_trabaja {
    margin: 24px 0;
  }

  .footer__container_content_two_redes {
    margin: 0;
    width: auto;
    justify-content: center;
  }

  .footer__container_content_two_redes img,
  .footer__container_content_two_redes span {
    margin: 0 4px;
  }

  .footer__container_content_one_contacto_trabaja_sugerencias {
    justify-self: start;
  }

  .footer__container_content_one_acreditaciones {
    position: static;
    justify-self: start;
  }

  .footer__container_content_one_logo_nosacompaña {
    justify-self: start;
  }

  .footer__container_content_one_nosacompaña{
    height: fit-content;
  }

  .footer__container_content_one_nosacompaña_img {
    flex-direction: row;
    height: fit-content;
  }

  .footer__container_content_one_nosacompaña_img img {
    margin: 16px 0;
  }

  .footer__jesuitas {
    margin-left: 24px !important;
  }

  .footer__container_content_two_datafiscal_pdp {
    justify-self: start;
  }
  .footer__container_content_two_derechos {
    margin: 0;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .footer__container_main {
    /* top: 2004px; */
    height: 950px;
  }

  .footer__container_content {
    height: 880px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(13, auto);
    grid-template-areas:
      "sedes"
      "line1"
      "contacto"
      "line2"
      "redes"
      "line3"
      "nosacompaña"
      "line4"
      "acreditaciones"
      "line5"
      "datafiscal"
      "line6"
      "derechos";

    gap: 0;
    row-gap: 16px;
  }

  .footer__line3,
  .footer__line4,
  .footer__line5,
  .footer__line6 {
    background-color: #11589680;
    grid-area: line2;
    width: auto;
    height: 1px;
    align-self: center;
  }

  .footer__line3 {
    grid-area: line3;
  }

  .footer__line4 {
    grid-area: line4;
  }

  .footer__line5 {
    grid-area: line5;
  }

  .footer__line6 {
    grid-area: line6;
  }

  .footer__container_content_one_trabaja {
    margin-bottom: 10px;
  }

  .footer__contacto_line {
    background-color: #11589680;
    width: 100%;
    height: 1px;
    position: relative;
    top: 10px;
  }

  .footer__container_content_one_contacto_trabaja_sugerencias {
    width: 100%;
  }
}

/* Media query solo para ajustar tamaños */
@media screen and (max-width: 390px) {
  .footer__container_main {
    height: 980px;
  }
}