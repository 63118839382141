.headerInstitutional {
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
}

.sectionInstitutional {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.institutional {
    width: 60%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.i {
    display: flex;
    justify-content: flex-end;
}

/* .admin {
    display: none;
} */

.title {
    display: inline;
    text-align: center;
}

.container {
    display: flex;
    justify-content: center;
}