.container_btn{
    display: flex;
    margin: 5px 0 10px 10px;
}
.btn {
    background-color: var(--main-blue-color);
    color: white;
    border: none;
    border-radius: 4px;
    width: fit-content;
    padding: 5px 10px;
}
.filtros{
    display: flex;
    justify-content: space-around;
    margin: 15px 5px ;
}