.father {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.title {
  margin-bottom: 30px;
  font-family: Merriweather Sans;
  font-size: 40px;
}
.container {
  display: flex;
  justify-content: space-around;
}
.containerDispositive {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dispositive {
  margin: 0;
  font-family: Merriweather Sans;
  font-size: 20px;
}
.btn {
  background-color: green;
  color: white;
  border: none;
  border-radius: 6px;
  margin: 10px;
  font-family: Merriweather Sans;
}
.imgD {
  width: 480px;
  /* height: 480px; */
  margin-bottom: 20px;
  box-shadow: 1px -1px 10px 5px rgba(17, 88, 150, 0.5);
}
.imgM {
  width: 272px;
  margin-bottom: 20px;
  box-shadow: 1px -1px 10px 5px rgba(17, 88, 150, 0.5);
}

.containerModal {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.btnOn {
  background-color: green;
  color: white;
  border: none;
  border-radius: 6px;
  margin: 10px;
  width: 200px;
  height: 40px;
  font-family: Merriweather Sans;
}
.btnGrabar {
  background-color: #002856;
  color: white;
  border: none;
  border-radius: 6px;
  margin: 10px;
  width: 200px;
  height: 40px;
  font-family: Merriweather Sans;
}
.btnOff {
  background-color: red;
  color: white;
  border: none;
  border-radius: 6px;
  margin: 10px;
  width: 200px;
  height: 40px;
  font-family: Merriweather Sans;
}