.container__form {
  display: grid;
  grid-template-columns: 320px 320px;
  grid-template-rows: repeat(5, 68px) 127px 32px;
  grid-template-areas:
    "nombre apellido"
    "localidad email"
    "telefono fechaDeNacimiento"
    "obraSocial obraSocial"
    "dni dni"
    "mensaje mensaje"
    "loading loading"
    "btn btn";
  gap: 24px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container__label_input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
}
.container__label_input label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  color: var(--main-light-blue-color);
}
.container__label_input input,
.container__label_input textarea {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  color: var(--main-blue-color);
  background-color: var(--main-hover-default-color);
  border-radius: 6px;
  margin: 0;
  padding: 8px 0 6px 16px;
  border: none !important;
  outline: none !important;
  width: 100%;
  resize: none;
}
.container__label_input input:hover,
.container__label_input textarea:hover {
  background-color: var(--main-hover-default-color);
}
.container__label_input input:focus,
.container__label_input textarea:focus {
  background-color: var(--main-hover-default-color);
  outline: 1px solid rgba(17, 88, 150, 0.5) !important;
}
.container__telefono_input {
  display: flex;
  gap: 8px;
}
.telefono__prefijo {
  width: 84px !important;
}
.telefono__numero {
  width: 228px !important;
}
.container__nombre {
  grid-area: nombre;
}
.container__apellido {
  grid-area: apellido;
}

.container__dni {
  grid-area: dni;
}
.container__email {
  grid-area: email;
}
.container__localidad {
  grid-area: localidad;
  position: relative;
}

.icono__flecha {
  position: absolute;
  right: 16px !important;
  top: 36px;
  transition: all 0.1s ease-in-out;
}
.container__telefono {
  grid-area: telefono;
}
.container__fechaDeNacimiento {
  grid-area: fechaDeNacimiento;
}
.container__fechaDeNacimiento input::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator {
  filter: brightness(0) saturate(100%) invert(15%) sepia(76%) saturate(3805%)
    hue-rotate(196deg) brightness(93%) contrast(87%);
  margin-right: 11px;
}
.container__obraSocial {
  grid-area: obraSocial;
}
.container__mensaje {
  grid-area: mensaje;
}
.container__pedidos {
  grid-area: pedidos;
  position: relative;
}
.container__pedidos input {
  display: none;
}
.label__custom {
  background-color: var(--main-light-blue-color);
  width: 112px;
  height: 32px;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: -0.32px !important;
  line-height: 15px !important;
  font-family: Merriweather Sans !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8.5px 12px;
  border-radius: 6px;
  cursor: pointer;
  position: absolute;
  top: 58px;
}

.label__formatos {
  color: #575757 !important;
}

.archivoCargado__btn {
  margin-right: 8px;
}

.archivoCargado__btn_close {
  cursor: pointer;
  margin-left: 8px;
}

.container__label_archivoCargado {
  display: flex;
  align-items: center;
}

.container__localidades_resultados {
  position: absolute;
  height: fit-content;
  max-height: 224px;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  border-radius: 0px 0px 6px 6px;
  padding: 0;
  transition: all 0.1s ease-in-out;
  top: 72px;
  z-index: 1;
}

.noshow_localidades {
  height: 0 !important;
}
.container__localidades_resultados li {
  list-style: none;
  text-align: left;
  color: var(--main-light-blue-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  cursor: pointer;
  padding: 16px;
}

.container__localidades_resultados li:hover {
  font-weight: 700;
  background: rgba(17, 88, 150, 0.05);
  color: var(--main-blue-color);
}

.invertir__chevron {
  transform: rotate(180deg);
}

.btn__enviar {
  grid-area: btn;
  justify-self: center;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.32px;
  font-weight: 400;
  font-family: Merriweather Sans;
  color: var(--main-blue-color);
  background-color: var(--main-yellow-color);
  width: 107px;
  height: 25px;
  border: none;
  border-radius: 4px;
}
.disabled {
  opacity: 0.3;
}

.buttonConfirm {
  width: 240px;
  height: 32px;
  background-color: var(--main-blue-color);
  color: #fff;
  align-self: center;
  justify-self: center;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  border-radius: 4px;
  margin: 0;
  margin-bottom: 22px;
}

.titleAlert {
  color: var(--main-blue-color) !important;
  font-family: Merriweather Sans !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: -0.32px !important;
  width: 240px;
  justify-self: center;
  padding: 0 !important;
  margin: 0 auto !important;
  margin-top: 19px !important;
}

.containerAlert {
  width: 386px !important;
  height: 194px;
  display: flex !important;
  justify-content: space-around;
  flex-direction: column;
  position: static !important;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5) !important;
  padding: 0 !important;
}

.containerHtml {
  margin: 0 !important;
  overflow: hidden !important;
  margin-top: 12px !important;
}

.cancelAlert {
  width: 386px;
  height: 148px;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5) !important;
}

.no_btn {
  width: 108px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #002856;
  border-radius: 4px;
  color: var(--main-blue-color);
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.32px;
  font-weight: 400;
  font-family: Merriweather Sans;
}

.eliminar_btn {
  width: 108px;
  height: 32px;
  background-color: var(--main-blue-color);
  border: 1px solid #002856;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.32px;
  font-weight: 400;
  font-family: Merriweather Sans;
  margin-left: 24px;
}

.loadingContainer {
  grid-area: loading;
  height: auto;
  display: flex;
  justify-content: center;
}

.error__message {
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  color: #ff4949;
  position: absolute;
  bottom: -20px;
}
.not__empty {
  background-color: var(--main-hover-default-color) !important;
}
/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .container__form {
    grid-template-columns: 244px 244px;
  }
  .telefono__numero {
    width: 152px !important;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__form {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 68px) 127px 32px;
    grid-template-areas:
      "nombre"
      "apellido"
      "localidad"
      "email"
      "telefono"
      "fechaDeNacimiento"
      "dni"
      "obraSocial"
      "mensaje"
      "btn";
  }
  .telefono__numero {
    width: 100% !important;
  }
  .container__telefono_input {
    width: 100%;
  }
  .containerAlert {
    width: 272px !important;
  }
  .cancelAlert {
    width: 272px !important;
  }
}
