.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.form {
  width: 1100px;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.name {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.nameLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.nameInput {
  width: 100% !important;
}

.icon {
  width: 100%;
  height: 150px;
  max-height: 150px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.iconLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.iconInput {
  width: 100% !important;
}

.inputContainer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.inputContainer input {
  display: none;
}

.iconElement {
  color: #002856;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.indications {
    margin-top: 20px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    padding: 10px;
  }
  
  .indicationsLabel {
    width: 100%;
    background: #002856;
    color: #fff;
  }

  .indicationsContainerButton {
    margin-top: 10px;
  }

.containerNew {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.containerNew label {
    margin-right: 10px;
}

.containerNew input {
    margin-right: 10px;
}

.containerNew button {
    width: 80px;
  border: none;
  padding: 5px;
  background-color: #002856;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
}

.items {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.x {
    color: red;
    cursor: pointer;
    width: 20px;
    margin-right: 5px;
  }

  .itemCancel {
      display: flex;
      align-items: center;
  }

  .buttonCont {
      width: 100%;
      padding-right: 20px;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
  }

  .buttonSubmit {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: #002856;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 14px;
  }