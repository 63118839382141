.container__content {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.container__content li {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #115896;
  padding: 8px;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: all 0.1s linear;
  list-style: none;
}

.container__content li:hover {
  background-color: rgba(17, 88, 150, 0.1);
}

.container__content li:active {
  background-color: rgba(17, 88, 150, 0.15);
}

.selected {
  background-color: #115896 !important;
  color: #fff !important;
}

.paginado__separacion {
  width: 2px;
  height: 32px;
  background-color: rgba(17, 88, 150, 0.15);
  margin: 0 4px;
}

.container__dobleFlecha {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.container__dobleFlecha img {
  width: 16px;
  height: 16px;
}

.container__dobleFlecha:hover {
  background-color: rgba(17, 88, 150, 0.1);
}

.container__dobleFlecha:active {
  background-color: rgba(17, 88, 150, 0.15);
}

.container__dobleFlecha_back {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.container__dobleFlecha_back img {
  width: 16px;
  height: 16px;
  transform: rotate(-180deg);
}

.container__dobleFlecha_back:hover {
  background-color: rgba(17, 88, 150, 0.1);
}

.container__dobleFlecha_back:active {
  background-color: rgba(17, 88, 150, 0.15);
}

.disabled {
  opacity: 0.3;
}

.paginado__puntos_suspensivos_mas {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginado__puntos_suspensivos_mas span {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #115896;
  padding: 8px;
  border-radius: 6px;
  width: 32px;
  height: 32px;
}

.paginado__puntos_suspensivos_menos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginado__puntos_suspensivos_menos span {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #115896;
  padding: 8px;
  border-radius: 6px;
  width: 32px;
  height: 32px;
}
