.container__statistics_main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container__statistics_content {
  background-color: #002856;
  width: 1128px;
  height: 232px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  overflow: hidden;
  position: relative;
  font-family: Merriweather Sans !important;
  margin: 0 24px;
}

.statistics__image {
  width: 60px !important;
  height: 60px;
  margin-bottom: 10px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(25%)
    hue-rotate(70deg) brightness(108%) contrast(108%);
}

.container__statistics_individual {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 216px;
  height: 180px;
}
.container__statistics_individual p {
  height: 40px;
}

.container__statistics_individual span {
  color: #e2b900;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 5px;
}

.container__statistics_individual p {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.statistics__line {
  background-color: #115896;
  height: 93px;
  width: 4px;
  margin: 0 30px;
  margin-bottom: 10px;
}

.statistics__block {
  background-color: #002856;
  width: 30px;
  height: 100%;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
}

/* Media query para tablet grande */
@media screen and (max-width: 1175px) {
  .container__statistics_content {
    width: 744px;
    height: 200px;
  }
  .container__statistics_individual {
    width: 168px;
    height: 150px;
  }
  .container__statistics_individual span {
    font-size: 28px;
    line-height: 35px;
  }
  .container__statistics_individual p {
    font-size: 14px;
    line-height: 20px;
  }
  .statistics__image {
    width: 48px !important;
    height: 48px !important;
  }
  .statistics__line {
    margin: 0 18px;
    margin-bottom: 15px;
  }
  .statistics__block {
    width: 75px;
  }
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .container__statistics_content {
    width: 560px;
  }
  .statistics__line {
    margin: 0;
    margin-bottom: 15px;
  }
  .statistics__block {
    width: 30px;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__statistics_content {
    width: 100%;
    border-radius: 0px;
    margin: 0;
    box-shadow: none;
  }
  .statistics__block {
    display: none;
  }
}
