.container__main {
  background-color: #fff;
  display: flex;
  border-radius: 6px;
  height: 100%;
  grid-area: columnIzq;
}

.container__content {
  width: 664px;
  margin: 40px;
  position: relative;
}

.container__title_select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(17, 88, 150, 0.15);
  padding-bottom: 8px;
  position: relative;
}

.container__title_select h3 {
  color: #115896;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  margin: 0;
}

.chevron_up,
.chevron_down {
  margin-left: 8px;
  transition: all 0.1s ease-in-out;
}

.chevron_down {
  transform: rotate(180deg);
}

.order {
  border: none;
  color: #115896;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 24px;
  font-family: Merriweather Sans;
  padding: 0 8px 0 0;
  cursor: pointer;
}

.container__noticias {
  width: 100%;
}

.container__paginado {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container__paginado_content {
  display: flex;
  align-items: center;
}

.paginado {
  display: flex;
}

.paginado span {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #115896;
  padding: 8px;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.paginado span:hover {
  background-color: rgba(17, 88, 150, 0.1);
}

.paginado span:active {
  background-color: rgba(17, 88, 150, 0.15);
}

.paginado__separacion {
  width: 2px;
  height: 32px;
  background-color: rgba(17, 88, 150, 0.15);
  margin: 0 4px;
}

.container__dobleFlecha {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.container__dobleFlecha img {
  width: 16px;
  height: 16px;
}

.container__dobleFlecha:hover {
  background-color: rgba(17, 88, 150, 0.1);
}

.container__dobleFlecha:active {
  background-color: rgba(17, 88, 150, 0.15);
}

.flecha__selected {
  background-color: #115896 !important;
}

.flecha__selected_img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(117deg) brightness(115%) contrast(100%) !important;
}

.selected {
  background-color: #115896 !important;
  color: #fff !important;
}

.flecha_bidireccional {
  display: none;
}

/* Media query para tablet grande */
@media screen and (max-width: 1175px) {
  .container__title_select {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 12px;
  }

  .container__title_select h3 {
    margin-bottom: 12px;
  }

  .flecha_bidireccional {
    display: block;
    position: absolute;
    right: 0;
    top: 4px;
    cursor: pointer;
  }
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .container__content {
    width: 512px;
    margin: 40px 24px;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__main {
    width: 100%;
    border-radius: 0;
  }

  .container__content {
    width: 100%;
    margin: 40px auto;
  }

  .container__title_select {
    padding: 0 24px;
    padding-bottom: 12px;
    width: 100%;
  }

  .container__title_select h3 {
    width: fit-content;
    text-align: left;
    margin-right: 24px;
  }

  .flecha_bidireccional {
    right: 24px;
  }
}
