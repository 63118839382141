.image-uploadFile {
    width: 80px;
    height: 80px;
}

.upload-image {
    width: auto;
    height: auto;
    padding: 15px;
    display: flex;
    justify-content:space-evenly;
}

.progress {
    height: 30px;
    display: flex;
    flex-direction: row;
    background-color: #FFF !important;
    align-items: center;
    padding: 20px;
}

.progress-bar {
    align-items: center;
    border:none;
    border-radius: 20px;
    margin-right: 10px;
}

.input-image {
    display: flex;
    align-items: center;
}

.image-select {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.placeholder-image {
    display: flex;
    justify-content: center;
}

.button-clipboard {
    width: 85px;
    height: auto;
    font-size: 10px;
}