.container__main {
  border-radius: 6px;
  background-color: #fff;
  height: 100%;
  grid-area: columnIzq;
}

.container__content {
  width: 552px;
  margin: 40px 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  text-align: left;
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #575757;
}

.content_fecha_hora {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: -0.32px;
  font-family: Merriweather;
  margin-bottom: 8px;
  color: #002856;
}

.content_title {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.32px;
  line-height: 30px;
  font-family: Merriweather;
  margin-bottom: 24px;
  color: #002856;
}

.content_image {
  width: 100%;
  height: 367px;
  border: 6px;
  margin-bottom: 24px;
  object-fit: cover;
}

.content__contenido {
  text-align: left;
  font-family: "Merriweather Sans" !important;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.32px;
  margin: 0;
  color: #002856;
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .container__content {
    width: 480px;
    margin: 40px;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__main {
    padding: 40px 24px;
  }

  .container__content {
    width: 100%;
    margin: 0;
  }

  .content_image {
    width: 100%;
    height: 100%;
    min-width: 272px;
    min-height: 180px;
  }
}
