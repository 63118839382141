.container__main {
  width: 100%;
}

.container__content {
  width: 100%;
}

.p {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17.6px !important;
  color: #575757;
  text-align: start;
  margin-left: 1px;
  width: 100%;
}
