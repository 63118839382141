.changeImg {
  width: 90px;
  height: 100%;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  background: #002856;
  margin-top: 20px;
}

.searchImage {
  cursor: pointer;
}

.nextEditButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 0px 20px;
}

.formEditSpecialties {
    width: 800px;
    height: 100%;
    border: solid 1px #D5D8DC;
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.input-name-specialty {
  width: 100%;
  border: solid red 1px;
}