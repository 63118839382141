.modal {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    top: 0;
    z-index: 100;
}

.containerRaitingComponent {
    position: fixed;
    top: 254px;
    right: 0px;
    z-index: 150 !important;
    -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    /* border: 1px solid red; */
  }
  
  .maskBodyHome {
    width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 200;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    top: 0px;
  }