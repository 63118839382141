.container__main {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.container__content {
  width: 664px;
  margin: 24px 40px 40px 40px;
}

.content_title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  color: var(--main-blue-color);
  border-bottom: 1px solid rgba(17, 88, 150, 0.15);
  padding-bottom: 8px;
  text-align: left;
  margin: 0;
  margin-bottom: 24px;
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .container__content {
    width: 512px;
    margin: 24px 24px 40px 24px;
  }
  .content_title {
    font-size: 24px;
    line-height: 32px;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__content {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
