.NavBarContainer {
  width: 100%;
  height: 58px;
  background-color: #002856;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavBarContainerSub {
  width: 1128px;
  height: 64px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  font-family: Merriweather Sans !important;
}

.ulPrincipal {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  margin-left: -15px;
}

.navBarResponsiveContainer {
  display: none;
}

/* --------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1175px) {
  .NavBarContainerSub {
    display: none;
  }

  .NavBarContainer {
    justify-content: flex-end;
  }

  .navBarResponsiveContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media only screen and (max-width: 607px) {
  .NavBarContainer {
    height: 48px;
  }
}
