.container__main {
  width: 100%;
  border-bottom: 1px solid rgba(17, 88, 150, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container__content {
  width: 100%;
  margin: 24px 0;
  display: flex;
}

.noticias__image {
  width: 240px;
  height: 160px;
  object-fit: cover;
  border-radius: 6px;
}

.container__description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  width: 424px;
  height: 160px;
  position: relative;
}

.container__description span {
  color: #002856;
  font-family: Merriweather;
}

.container__fecha {
  margin-bottom: 4px;
  line-height: 15px;
  text-align: left;
}

.description__fecha {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.32px;
}

.description__title {
  text-align: left;
  line-height: 17px;
  margin-bottom: 2px;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-weight: 700;
}

.description__previa {
  height: 85px;
  text-align: left;
  line-height: 17px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: -0.32px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Merriweather Sans !important;
}

.container__leernota {
  cursor: pointer;
  line-height: 18px;
}

.container__leernota span {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.32px;
}

.flecha_leer_nota {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .container__previa {
    width: 240px;
    height: 68px;
    -webkit-line-clamp: 3;
  }
  .container__description {
    width: 272px;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__main {
    height: fit-content;
  }
  .container__content {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .container__noticias_image {
    width: 75vw;
    max-width: 404px;
    max-height: 268px;
    min-width: 272px;
    min-height: 180px;
 
  }

  .container__previa {
    width: 100%;
  }

  .noticias__image {
    width: 100%;
    height: 100%;
    min-width: 272px;
    min-height: 180px;
    max-height: 270px;
  }

  .container__description {
    width: 75vw;
    height: 167px;
    padding: 16px 16px 0px 16px;
    min-width: 272px;
    max-width: 404px;
  }

  .container__leernota {
    margin-top: 12px;
  }
}
