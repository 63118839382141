.container {
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: center;
}

.form {
  width: 90%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.titleLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.titleInput {
  width: 100% !important;
}
.resume {
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.textAreaResume {
  width: 100%;
  height: 100px;
}

.resumeLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.image {
  height: 100px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.img {
  /* width: 60px; */
  height: 60px;
}

.deleteImg {
  color: red;
  margin-right: 10px;
}

.imageLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.inputImg {
  display: none;
}

.imageContainer {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.area {
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.areaLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.selectArea {
  border: none;
}

.spanContainer {
  margin: 10px;
}

.cancel {
  color: red;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.specialty {
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.featured {
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.featuredLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.featuredInputLabel {
  margin: 10px;
  color: black;
}

.news {
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.newsLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.finish {
  margin-top: 20px;
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
  display: flex;
  justify-content: flex-end;
}

.buttonFinish {
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: #002856;
  color: #fff;
  margin: 10px;
}

.buttonContent {
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: #002856;
  color: #fff;
  margin: 10px;
}

.modal {
  position: fixed !important;
  z-index: 999 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  min-height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalButtonContainer {
  background: #fff;
  width: 100%;
  height: 100%;
}

.openModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: transparent;
  padding: 10px;
  border: none;
  /* margin: 20px;  */
}

.modalImageContainer {
  display: flex;
}

.modalImageContainerOff {
  display: none;
}

.modalContainer {
  width: 70%;
  height: 70% !important;
}

.containerButtonGroup {
  width: 70%;
  display: flex;
  justify-content: space-around;
  background-color: white;
  border-top: solid 1px lightgray;
}

.active {
  width: 100px;
  height: auto;
  padding: 10px;
  border-radius: 6px;
  background-color: red;
  color: #fff;
  margin: 10px;
  border: none;
}

.activeOff {
  width: 100px;
  height: auto;
  border-radius: 6px;
  background-color: teal;
  color: #fff;
  margin: 10px;
  border: none;
  padding: 10px;
}

.containerActive {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
