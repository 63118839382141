.App {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  overflow: hidden;
}

.App-modal {
  width: 100%;
  height: 100vh;
  display: flex;
  text-align: center;
  justify-content: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.containerRaitingComponent {
  position: fixed;
  top: 254px;
  right: 0px;
  z-index: 150 !important;
  -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  /* border: 1px solid red; */
}

#emoji {
  width: 48px;
  height: 48px;
  position: fixed !important;
  top: 254px;
  right: 0px;
  z-index: 110 !important;
  background: #ffffff;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
