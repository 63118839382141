.container {
    width: 100%;
    /* height: 100%; */
}

.modal {
    position: fixed !important;
    z-index: 999 !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    min-height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.75) !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .modalNewOff {
    display: none;
  }

  .modalContainer {
    position: relative;
      padding: 1rem;
      width: 80% !important;
      height: 600px !important;
      overflow-y: auto;
      background-color: #fff;
    }

.formMarkdown {
    width: 100%;
    height: auto;
    
}

.containerButtonGroup {
    width: 80%;
    display: flex;
    justify-content: space-around;
    background-color: white;
    border-top: solid 1px lightgray;
  }

  .newSection {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
  }

  .sectionButton {
    border: none;
    border-radius: 10px;
    /* padding: 5px 10px; */
    background-color: teal;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    margin: 20px;
    width: 110px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }

  .sectionButtonSave {
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #002856;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    margin: 20px;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;  
  }

  .cancelIcon {
      margin-left: 10px;
      color: red;
  }
  .inputGroup {
    margin-top: 20px; /* Añadir margen superior para separar del editor */
  }
  
  .inputGroup {
    display: flex;
    align-items: center; /* Alinea verticalmente el label y el input */
    margin-top: 20px; /* Espaciado superior del grupo */
  }
  
  .inputGroup label {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px; /* Espacio entre el label y el input */
    width: 100px; /* Ajusta el ancho del label para que no sea demasiado grande */
  }
  
  .inputGroup input {
    flex: 1; /* El input ocupa todo el espacio restante */
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  