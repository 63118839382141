.containerGral {
    width: 360px;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;
    height: 100%;
    grid-area: columnIzq;
  }
  
  .siteContainer {
    width: 312px;
    height: min-content;
    background: rgba(17, 88, 150, 0.1);
    border-radius: 6px;
    padding: 24px 24px 28px 24px;
    margin: 32px 24px 0 24px;
  }
  .section {
    height: max-content !important;
  }
  
  /* .img {
    filter: brightness(0) saturate(100%) invert(24%) sepia(98%) saturate(1044%)
      hue-rotate(183deg) brightness(91%) contrast(91%);
  } */
  .artContainer {
    height: max-content !important;
    margin: 0 !important;
    margin-top: 16px !important;
  }
  .spanContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  .spanContainer span {
    font-family: Merriweather Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: #002856;
  }
  
  .siteContainer article {
    width: 100%;
    height: 100%;
    margin-top: 17px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .neighborhoodContainer {
    width: 274.64px;
    height: max-content;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0;
    margin-bottom: 16px;
  }
  
  .neighborhood {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    padding: 0px;
  }
  
  .neighborhood span {
    font-family: Merriweather Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140.69%;
    color: #115896;
    margin-left: 8.5px;
  }
  
  .telemedicinaGral {
    width: 274.64px;
    height: 104px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0;
  }
  
  .telemedicinaContainer {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    padding: 0px;
  }
  
  .telemedicina {
    font-family: Merriweather Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140.69%;
    color: #115896;
    text-align: start;
  }
  
  .telemedicinaSpan {
    font-family: "Merriweather Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: #115896;
    text-align: start;
  }
  
  .telemedicinaImg {
    /* width: 24px;
    height: 24px; */
    margin-right: 8.03px;
  }
  
  .site {
    width: 222px;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 8px 0px 0px 32px;
  }
  
  .name {
    font-family: Merriweather Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140.69%;
    color: #115896;
    text-align: start;
    margin-bottom: 5px;
  }
  
  .direccion {
    font-family: Merriweather Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.32px;
    color: #115896;
    text-align: start;
    margin-bottom: 5px;
  }
  
  .tel {
    font-family: Merriweather;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #002856;
    text-align: start;
  }
  
  .title__column {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.32px;
    font-family: Merriweather Sans;
    margin-right: auto;
    margin-bottom: 32px;
    color: #002856;
  }
  
  .sectionContainer {
    width: 312px;
  }
  
  .buttonSection {
    width: 100%;
    margin: 2px 0;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    border-radius: 6px;
    color: #115896;
    border: none;
    padding: 12px;
    text-align: start;
  }
  .buttonSection:hover {
    background: rgba(17, 88, 150, 0.1);
  }
  
  .buttonSection:active {
    background: rgba(17, 88, 150, 0.15);
  }
  
  .container__sections {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .container__sections li {
    list-style: none;
    text-align: left;
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-family: Merriweather Sans;
    border-radius: 6px;
    color: #115896;
    cursor: pointer;
    width: 100%;
  }
  .container__sections li:hover {
    background: rgba(17, 88, 150, 0.1);
  }
  
  .container__sections li:active {
    background: rgba(17, 88, 150, 0.15);
  }
  
  .selected {
    background-color: #115896 !important;
    color: #fff !important;
  }
  
  .busquedasContainer {
    width: 100%;
  }
  
  .container__titleBusqueda {
    width: 100%;
    margin-top: 28px;
    border-bottom: 3px solid rgba(17, 88, 150, 0.15);
    padding: 12px 0;
    display: flex;
    margin-bottom: 4px;
  }
  
  .title__busquedas {
    font-family: Merriweather Sans;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-weight: 700;
    color: #002856;
  }
  
  .notBusquedas_container {
    width: 100%;
    margin-top: 20px;
    display: flex;
  }
  
  .notBusquedas_container span {
    font-family: Merriweather Sans;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-weight: 500;
  }
  
  .buttonSelect {
    margin: 4px 0;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #115896;
    border-radius: 6px;
    color: #fff;
    border: none;
    padding: 12px;
    text-align: start;
  }
  
  .turno {
    width: 169px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #002856;
    border-radius: 4px;
    color: #fff;
    border: none;
    font-weight: 400;
    font-size: 16px;
  }
  
  .containerButton {
    width: 264px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  .close {
    display: none;
  }
  
  .seccionesContainer {
    height: 32px;
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  @media screen and (max-width: 1175px) {
    .containerGral {
      box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
      transition: all 0.5s ease-in-out;
      position: relative;
      z-index: 1;
      right: 180px;
    }
    .false {
      transform: translateX(150vw);
    }
    .close {
      display: flex;
      cursor: pointer;
      /* position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer; */
    }
  
    .title__column {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.32px;
      font-family: Merriweather Sans;
      margin-right: auto;
      margin-bottom: 0;
      color: #002856;
      /* margin-left: 24px;
      margin-top: 12px;
      text-align: left;
      padding: 0; */
    }
  
    .seccionesContainer {
      height: 32px;
      margin-bottom: 32px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 607px) {
    .siteContainer {
      width: 100%;
      height: fit-content;
      margin-top: 16px;
    }
    .containerGral {
      width: 320px;
      right: 160px;
      border-radius: 0px;
      padding: 0;
    }
    .sectionContainer {
      width: 100%;
      padding: 0 24px 0 24px;
    }
  
    .seccionesContainer {
      height: 56px;
      margin-bottom: 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      padding-right: 21.64px;
      padding-left: 24px;
    }
  
    .title__column {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.32px;
      font-family: Merriweather Sans;
      margin-right: auto;
      margin-bottom: 0px;
      color: #002856;
      margin-left: 0px;
      margin-top: 0px;
      text-align: left;
      padding: 0;
    }
  
    .buttonSelect {
      margin: 4px 0;
      width: 272px;
      height: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #115896;
      border-radius: 6px;
      color: #fff;
      border: none;
      padding: 12px;
      text-align: start;
    }
  
    .buttonSection {
      width: 272px;
      margin: 2px 0;
      height: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #fff;
      border-radius: 6px;
      color: #115896;
      border: none;
      padding: 12px;
      text-align: start;
    }
    .buttonSection:hover {
      background: rgba(17, 88, 150, 0.1);
    }
    
    .buttonSection:active {
      background: rgba(17, 88, 150, 0.15);
    }
    .busquedasContainer {
      padding: 0 24px;
    }
  }
  