.contenidocentral__container_main {
  width: 744px;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  grid-area: columnDer;
}

.contenidocentral__container_content {
  width: 100%;
  margin: 40px;
  text-align: left;
  position: relative;
}

.contenidocentral__seccion {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.32px;
  margin: 0;
  color: #002856;
  font-family: Merriweather Sans !important;
}

.contenidocentral__searchbar {
  width: 336px !important;
  height: 40px;
  border: 1px solid #115896 !important;
  border-radius: 6px !important;
  font-family: Merriweather Sans;
  padding: 7px 12px !important;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: -0.32px;
  margin-bottom: 0 !important;
  outline: none;
}

.contenidocentral__search_icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.contenidocentral__container_searchbar {
  position: relative;
  /* margin-bottom: 32px; */
  margin-top: 16px;
  width: fit-content;
}

.contentMarkdownContent {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #575757;
}

.contenidocentral__separacion_filtrado {
  background-color: #11589626;
  width: 2px;
  height: 40px;
  margin: 0 4px;
  margin-top: 32px;
}

.contenidocentral__container_filtrado {
  display: flex;
  align-items: center;
  /* margin-top: 32px; */
}

.contenidocentral__container_filtrado span {
  font-family: Merriweather Sans !important;
  color: #115896;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 32px;
  width: 56px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenidocentral__container_filtrado span:hover {
  background: rgba(17, 88, 150, 0.1);
}

.contenidocentral__container_filtrado span:active {
  background: rgba(17, 88, 150, 0.15);
}

.no_margin {
  margin-left: 0;
}

.contenidocentral__filtrado_active {
  background-color: #115896 !important  ;
  color: #fff !important;
}

.contenidocentral__container_filtros_line {
  display: flex;
}

.contenidocentral__container_content_filtrado {
  width: 100%;
  height: max-content;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.contenidocentral__content_filtrado {
  width: 320px;
}

.contenidocentral__filtrado_letra {
  color: #002856;
  font-family: Merriweather Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.32px;
  background-color: #fdf5ea;
  padding: 8px 12px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.contenidocentral__filtrado_ul {
  padding: 0;
  margin-left: 12px;
  margin-bottom: 24px;
}

.contenidocentral__filtrado_ul li {
  list-style: none;
  color: #115896;
  font-family: Merriweather Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.32px;
  cursor: pointer;
}
/* 
.ok_margin {
  margin-right: 10px;
} */

.sections__button {
  display: none;
}
.false {
  display: none;
}
.recomendaciones {
  width: 100%;
  max-height: 200px;
  background-color: #fff;
  position: absolute;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  border-radius: 6px;
  margin-top: 4px;
  overflow-y: auto;
  overflow-x: hidden;
}
.recomendaciones::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

.recomendaciones::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

.recomendaciones::-webkit-scrollbar-thumb {
  background-color: #79797986;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  /* border: 3px solid orange;  creates padding around scroll thumb */
}
.sugCont {
  width: 100%;
}
.option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 40px;
  /* border-top: rgb(238, 237, 236) 1px solid; */
  margin: 0;
  color: #115896;
  font-weight: 500;
  cursor: pointer;
  padding-left: 16px;
}
.option:hover {
  font-weight: bold;
  background-color: rgba(20, 20, 95, 0.11);
}
.division {
  height: 1px;
  width: 100%;
  background: rgba(17, 88, 150, 0.15);
  margin: 8px 0 24px 0;
}
/* Media query para tablet grande */
@media screen and (max-width: 1175px) {
  .contenidocentral__seccion {
    border-bottom: 1px solid rgba(17, 88, 150, 0.15);
    padding-bottom: 8px;
    margin-bottom: 32px;
  }

  .sections__button {
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
    cursor: pointer;
  }
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .contenidocentral__container_main {
    width: 560px;
  }

  .contenidocentral__container_content {
    width: 512px;
    margin: 40px 24px;
  }

  .order2 {
    order: 3;
  }

  .order3 {
    order: 2;
  }

  .contenidocentral__searchbar {
    width: 272px !important;
  }

  .contenidocentral__seccion {
    font-size: 24px;
    line-height: 32px;
  }
  .contenidocentral__container_filtrado {
    /* margin-left: -20px; */
    display: -webkit-inline-box;
    justify-content: center;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .contenidocentral__container_filtrado span {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }
  .contenidocentral__container_filtrado div {
    margin: 8px 0px 0px 1px;
  }
  .contenidocentral__separacion_filtrado {
    margin-left: 1px !important;
  }
  .ok_margin {
    margin: 0;
  }
  .contenidocentral__content_filtrado {
    width: 100%;
    margin-bottom: 24px;
  }
  .contenidocentral__filtrado_ul {
    margin-bottom: 0;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .contenidocentral__container_main {
    width: 100%;
    border-radius: 0;
    overflow: hidden;
    padding: 0 24px;
  }

  .contenidocentral__container_content {
    width: 100%;
  }

  .contenidocentral__container_filtrado {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}
