.container__main {
  height: 290px;
  width: 208px;
  display: flex;
  flex-direction: column;
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(17, 88, 150, 0.5));
}

.container__content {
  width: 100%;
  height: 160px;
  border-radius: 6px;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  bottom: 0;
  text-align: left;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.img__card {
  width: 208px;
  height: 138px;
  border-radius: 6px;
}

.btn__leerNota {
  cursor: pointer;
  width: fit-content;
}

.btn__leerNota span {
  margin-right: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  font-family: Merriweather;
  color: #002856;
}

.title {
  height: 43px;
  line-height: 17px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  font-family: Merriweather;
  color: #002856;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resume {
  height: 68px;
  font-size: 12px;
  font-weight: 300;
  font-family: Merriweather Sans;
  color: #002856;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}
