.carousel-body {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.BackofficeCarousel-container {
  width: 600px;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-shadow: 10px 10px 73px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 73px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 73px 0px rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

.image-carousel {
  width: 100%;
  height: 100%;
}

.input-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.input-carousel {
  display: none;
}

.h3-carousel {
  color: #555;
}

.icon-delete {
  width: 150px;
  background-color: white;
  border: red 1px solid;
  border-radius: 10px;
}

.open-file-icon {
  cursor: pointer;
  color: green;
}

.container-add-files {
  width: 150px;
  background-color: white;
  border: green 1px solid;
  border-radius: 10px;
}


.addDelete {
  margin-top: 15px;
}

.images__container{
  display: flex ;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}