.container {
  background-color: #e5e5e5;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.father {
  display: grid;
  grid-template-columns: 360px 744px;
  grid-template-areas: "columnaIzq columnaDer";
  column-gap: 24px;
  margin-bottom: 56px;
}
.container__flecha_home {
  width: 1128px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 24px;
}

.container__flecha_home_content {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.container__flecha_home_content span {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.32px;
  color: #115896;
  font-family: Merriweather Sans;
}

.flecha__home {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.columnader__container_main {
  padding: 40px;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  grid-area: columnaDer;
}
.img {
  display: none;
}
.columnader__container_content {
  width: 100%;
  text-align: left;
  position: relative;
}
.columnaizq__container_main {
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-bottom: 40px;
  grid-area: columnaIzq;
}
.columnaizq__container_content {
  width: 312px;
  margin: 40px 24px;
  text-align: left;
  font-family: Merriweather Sans;
}
.sectionContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.columnaizq__title {
  font-size: 24px;
  font-family: Merriweather Sans;
  font-weight: 700;
  color: #002856;
  margin: 40px 24px 32px 24px;
  width: 100%;
  height: max-content;
  text-align: start;
}
.columnaizq__subtitle {
  display: flex;
  margin-bottom: 0px;
  margin-left: 24px;
  margin-top: 40px;
  font-size: 18px;
  color: #002856;
  font-family: Merriweather Sans;
  font-weight: bold;
}
.columnaizq__div {
  height: 1px;
  width: 312px;
  background-color: rgba(17, 88, 150, 0.15);
  margin: 12px 24px 4px 24px;
}
.columnaizq__btn {
  width: 312px;
  height: 48px;
  margin: 4px 24px;
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  padding: 12px;
  color: #115896;
  font-family: Merriweather Sans;
  font-weight: bold;
  background-color: transparent;
  border-radius: 6px;
}
.columnaizq__btn:hover {
  background: rgba(17, 88, 150, 0.1);
}
.columnaizq__btn_active {
  width: 312px;
  height: 48px;
  margin: 4px 24px;
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  padding: 12px;
  color: white;
  font-family: Merriweather Sans;
  font-weight: bold;
  background-color: #115896;
  border-radius: 6px;
}
.option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 40px;
  /* border-top: rgb(238, 237, 236) 1px solid; */
  margin: 0;
  color: #115896;
  font-weight: 500;
  cursor: pointer;
  padding-left: 16px;
}
.option:hover {
  font-weight: bold;
  background-color: rgba(20, 20, 95, 0.11);
}
.title {
  font-size: 24px;
  font-family: "Merriweather Sans";
  font-weight: 700;
  color: #002856;
  margin-bottom: 40px;
}
.container__Infogral{
  display: flex;
  justify-content: space-between;
}
.flechaSection{
  display: none;
}

.contentMarkdownContent {
  font-family: 'Merriweather Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: -0.32px;
color: #575757;
}

@media screen and (max-width: 1175px) {
  .img {
    display: flex;
    position: absolute;
    top: 40px;
    right: 16px;
    cursor: pointer;
  }
  .container__flecha_home {
    width: 744px;
  }
  .father {
    grid-template-columns: auto 0px;
    grid-template-areas: "columnaDer columnaIzq";
    column-gap: 0;
  }
  .columnader__container_main{
    width: 744px !important;
  }
  .columnaizq__container_main {
    box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
    transition: all 0.5s ease-in-out;
    position: relative;
    right: 360px;
    z-index: 1;
    width: 360px;
  }
  .division {
    width: 272px;
  }
  .columnaizq__noshow {
    transform: translateX(150vw);
  }
  .close {
    display: flex;
    position: absolute;
    top: 42px;
    right: 20px;
    cursor: pointer;
  }
  .flechaSection{
    display: flex;
    height: max-content;
    position: relative;
    top: 2px;
    right: 0;
    cursor: pointer;
  }
}
@media screen and (max-width: 791px) {
  /* .father {
    width: 560px;
  } */
  .columnaizq__btn {
    width: 312px;
    min-height: 48px;
    height: max-content;
    text-align: start;
  }
  .columnaizq__btn_active {
    width: 312px;
  }
  .container__flecha_home {
    width: 560px;
    height: 24px;
    margin-bottom: 8px;
  }
  .container__flecha_home_content span {
    font-size: 12px;
    line-height: 15px;
  }
  .flecha__home {
    width: 12px;
    height: 12px;
  }
  .columnader__container_main {
    width: 560px !important;
    margin: 0;
    padding: 24px;
    margin-bottom: 56px;
    right: 320px;
  }
  .columnader__container_content {
    width: 100%;
    margin: 0px;
  }
}
@media screen and (max-width: 607px) {
  .columnaizq__btn {
    width: 272px;
    min-height: 48px;
    height: max-content;
    text-align: start;
  }
  .columnaizq__btn_active {
    width: 272px;
  }
  .container__flecha_home {
    margin: 230px 0px 13px 0px;
    position: absolute;
    z-index: 1;
    align-items: center;
    left: 24px;
    width: auto;
    
  }
  .flecha__home {
    margin-right: 12px;
    margin-left: 0;
  }
  .columnaizq__container_main {
    border-radius: 0 !important;
    width: 320px;
    right: 320px;
  }
  .columnaizq__container_content {
    width: 100%;
    margin: 0 !important;
    margin-left: 32px;
  }
  .columnader__container_main {
    width: 100% !important;
    border-radius: 0;
    padding: 24px;
    margin-bottom: 0;
  }
  .father{
    margin-bottom: 0;
  }
}
