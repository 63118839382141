.title {
  font-size: 32px;
  font-weight: 700;
  font-family: Merriweather Sans;
  color: #002856;
  margin: 0px;
  /* height: 40px; */
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdf5ea;
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.division {
  border: 1px solid rgba(17, 88, 150, 0.15);
  margin: 8px 0 24px 0;
}

.description {
  font-size: 18px;
  font-weight: 700;
  font-family: Merriweather Sans;
  color: #002856;
  margin: 0px;
  height: 32px;
}

.subtitle {
  font-size: 24px;
  font-weight: 700;
  font-family: Merriweather Sans;
  color: #115896;
  height: 32px;
  margin: 0;
  margin-top: 24px;
}

.instalacion {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 320px;
  /* height: 222px; */
  /* padding: 16px 0 16px 8px; */
}

.name {
  font-size: 18px;
  font-weight: 700;
  font-family: Merriweather Sans;
  color: #002856;
  width: 320px;
  /* height: 32px; */
  margin: 12px 0 12px 0;
}

.desc {
  color: #747678;
  font-weight: 400;
  font-size: 14px;
  font-family: Merriweather Sans;
  width: 320px;
  /* height: 18px; */
  margin: 0px;
}

.ul {
  padding-left: 20px;
  margin: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 24px;
  /* column-gap: 184px; */
  width: 100%;
}
.sections__button {
  display: none;
}
@media screen and (max-width: 1175px) {
  .sections__button {
    display: block;
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
  }
}
@media screen and (max-width: 791px) {
  .title {
    font-size: 24px;
    height: auto;
  }
  .sections__button {
    display: block;
    position: absolute;
    top: 3px;
    right: 0px;
    cursor: pointer;
  }
  .grid {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 607px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 385px) {
  .title {
    width: 300px;
  }
  .sections__button {
    display: block;
    position: absolute;
    top: 15px;
    right: 0px;
    cursor: pointer;
  }
}
