.contenedorGral {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px;
}

.contenedor {
    width: 500px;
    height: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 20px;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.puesto {
    display: flex;
    flex-direction: column;
    margin: 10px 0 10px 0;
}

.container1{
    display: flex;
    align-items: center;
}
.img{
    margin-right: 10px;
}
.btn{
    margin-top: 30px;
    background-color: var(--main-blue-color);
    color: white;
    border-radius: 50px;
    border: none;
    width: 200px;
}
.btn2{
    margin-top: 20px;
    background-color: var(--main-blue-color);
    color: white;
    border-radius: 50px;
    border: none;
    width: 200px;
}
.title{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title label,
.title2 label{
    margin-right: 10px;
}

.title input,
.title2 input{
    width: 100%;
}

.title select,
.title2 select{
    margin: 0 20px 0 0;
    width: 320px;
}
.title2{
    display: flex;
    margin: 10px;
    flex-direction: column;
    align-items: center;
}
.container{
    margin-top: 20px;
}
.container input{
    margin-top: 10px;
}
.title3 .css-2b097c-container {
    width: 100%; /* Hace que el contenedor del select ocupe el 100% del ancho disponible */
  }
  
  .title3 .css-yk16xz-control {
    width: 100%; /* Hace que el input del select ocupe el 100% del ancho del contenedor */
  }
  
  .title3 .css-1hb7zxy-IndicatorsContainer {
    display: flex;
  }
  
  .title3 .css-1okebmr-indicatorSeparator {
    display: none; /* Opcional: Ocultar el separador entre el input y el ícono de la flecha */
  }
  .title3{
    width: 100%;
  }
  