.container__main {
  width: 100%;
}

.container__content {
  position: relative;
  width: fit-content;
  display: grid;
  grid-template-columns: 744px 360px;
  grid-template-areas: "columnIzq columnDer";
  grid-template-rows: auto;
  justify-items: center;
  margin: 0 auto;
  margin-bottom: 56px;
  column-gap: 24px;
}

.container__btn_volver {
  width: 1128px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
}

.volver_span {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  color: #115896;
}

.btn_image {
  width: 24px;
  height: 24px;
}

/* Media query para tablet grande */
@media screen and (max-width: 1175px) {
  .container__content {
    width: 744px;
    grid-template-columns: auto 0px;
    grid-template-areas: "columnIzq columnDer";
    gap: 0;
  }
  .container__btn_volver {
    width: 744px;
  }
}

/* Media query para tablet chica */
@media screen and (max-width: 791px) {
  .container__content {
    width: 560px;
  }
  .container__btn_volver {
    height: 15px;
    margin-bottom: 8px;
    margin-top: 24px;
    width: 560px;
  }
  .volver_span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
  .btn_image {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

/* Media query para movil */
@media screen and (max-width: 607px) {
  .container__btn_volver {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  
  .container__btn_volver img{
    margin-left: 24px;
  }
  .container__content {
    width: 100%;
    margin: 0;
  }
}
