.container {
  /* min-height: 100%;
  max-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pantalla {
  width: 100vw;
}

.banner {
  position: relative;
  display: flex;
}

.bannerImage {
  height: 224px;
  width: 1920px;
  background-position: center center;
  background-size: cover;
}

.bannerCont {
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: 82px;
  margin-left: 396px;
}

.goHomeBtn {
  position: absolute;
  width: fit-content;
  margin-left: 396px;
  margin-top: 53px;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
}

.goHomeBtn img {
  margin-right: 8px;
}

.bannerImg {
  height: 36px;
  width: 36px;
}

.bannerTitle {
  margin: 0;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.32px;
  color: #ffffff;
  margin-left: 12px;
}

.gallery {
  display: flex;
  width: 1128px;
  height: 480px;
  margin-top: -75px;
  margin-bottom: 56px;
}

.gallery .containerImg {
  display: flex;
  height: 100%;
  flex: 1;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
}

.gallery .containerImg:hover {
  flex: 2;
}

.containerImg > img {
  flex: 1;
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  z-index: 1;
  position: absolute;
}

.filtro {
  height: 100%;
  width: 100%;
  z-index: 2;
  background: linear-gradient(0deg, #002856 0%, rgba(17, 88, 150, 0) 84.55%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 40px;
}

.logo {
  height: 32px;
  width: 32px;
  filter: brightness(0) saturate(100%) invert(74%) sepia(16%) saturate(7170%)
    hue-rotate(11deg) brightness(102%) contrast(102%);
  margin-bottom: 8px;
}

.sectionTitle {
  font-family: "Merriweather Sans";
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: white;
  margin-bottom: 8px;
}

.desc {
  display: none;
  width: 484px;
  margin: 0;
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #c8e5ff;
  text-align: start;
}

.containerImg:hover .desc {
  display: flex;
}

@media screen and (max-width: 1175px) {
  .bannerCont {
    margin-left: 616px;
  }
  .goHomeBtn {
    margin-left: 616px;
  }
  .gallery {
    flex-direction: column;
    height: 1080px;
    width: 664px;
  }
  .gallery .containerImg:hover {
    flex: 1;
  }

  .desc {
    display: flex;
  }
}
@media screen and (max-width: 791px) {
  .banner {
    width: 100vw;
  }
  .bannerImage {
    background-position: center center;
  }
  .bannerCont {
    margin-left: 24px;
  }
  .goHomeBtn {
    margin-left: 24px;
  }
  .bannerImg {
    height: 28px;
    width: 28px;
  }
  .bannerTitle {
    font-size: 32px;
  }
  .containerGallery {
    width: 100vw;
    padding-left: 24px;
    padding-right: 24px;
  }
  .gallery {
    height: 1080px;
    width: 100%;
  }
}
@media screen and (max-width: 608px) {
  .gallery {
    height: 840px;
  }
  .containerImg {
    height: 280px;
  }
  .desc {
    width: 100%;
  }
}
