.backoffice-modal-container {
    display: flex;
    position: fixed;
    z-index: 999;
    top:0;
    left:0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.75);
    /* display:none; */
    justify-content: center;
    align-items: center;
}

.backoffice-modal-container.is-open {
    display:flex;
}

.modal-container {
    justify-content: center;
    min-width: 420px;
    max-width: 680px;
    min-height: 400px;
    max-height: 800px;
    /* overflow-y: auto; */
    background-color: #fff;
    border: solid black 4px;
    
}

.button-container {
    display: flex;
    justify-content: flex-end;
}

.modal-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: transparent;
    padding: 10px;
    border: none;
}

.BackofficeUploadFiles {
    width: 100%;
    height: 100%;
    
}
