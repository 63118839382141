.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.auth-card h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333;
}

.amplify-input {
    margin-bottom: 1rem;
    width: 100% !important;
    height: 100%;
    border: 1px solid #ced4da;
}

.auth-header {
    text-align: center;
    margin-bottom: 20px;
}

.auth-logo {
    max-width: 100px;
    margin-bottom: 10px;
}

/* Nuevos estilos para asegurar que los campos de entrada tengan la misma altura */
.amplify-input input {
    height: 48px; /* Ajusta esta altura según sea necesario */
    box-sizing: border-box; /* Asegura que el padding se incluya en la altura */
}

.amplify-input-password {
    height: 48px; /* Debe ser la misma altura que el campo de nombre de usuario */
}

.amplify-password-field {
    height: 48px; /* Asegura que el campo de contraseña tenga la misma altura */
}

.amplify-password-field input {
    height: 100%; /* Asegura que el input dentro del campo de contraseña ocupe todo el espacio disponible */
    box-sizing: border-box;
}

.custom-sign-in-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff; /* Cambia este color según tu diseño */
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}
  
.custom-sign-in-button:hover {
    background-color: #0056b3; /* Cambia este color según tu diseño */
}
