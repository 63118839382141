.main__container {
  width: 600px;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.content__container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  gap: 8px;
  position: relative;
}

.close_button__container {
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 24px;
}

.close_button__container:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.content__container img {
  width: 90%;
}

.form__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.form__container input {
  width: 70%;
  color: #000;
  border: 1px solid black;
}

.form__container button {
  width: 100px;
  border: none;
  background-color: transparent;
  border: 1px solid green;
  border-radius: 10px;
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
}
