.container {
  min-width: 100%;
  height: auto;
  /* border: solid red 2px; */
  padding: 0px;
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.formSpecialties {
  /* z-index: 2; */
  width: 800px;
  height: 100%;
  border: solid 1px #d5d8dc;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.headersLabel {
  background: #002856;
  color: white;
  margin: 10px 0px 10px 0px;
  width: 100%;
}

.inputName {
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid red 1px;
}

.formSpecialties textarea {
  width: 100%;
}

.principalProp {
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.adultsOrKids {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.adultsOrkidsOff {
  width: 300px;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.adultsOrkids label {
  text-align: start;
  margin: 10px 0px 10px 10px;
}

.uniOrKids {
  text-align: start;
  margin: 10px 0px 10px 10px;
}

.inputUni {
  margin-left: 10px;
}

.staffContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  margin: 10px 0px 10px 0px;
}

.staffContainer div {
  display: flex;
  flex-direction: column;
}

.staffContainer select {
  width: 300px;
  height: 30px;
}

.newStaffButton {
  width: 80px;
  height: 100%;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.staffContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 10px 0px 10px 0px;
}

.titleContainer {
  width: 100%;
  height: 40px;
  background: #002856;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.title {
  text-align: start;
  font-size: 20px;
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #e2b900;
  padding-left: 15px;
}

.siteContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.siteMap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 10px 10px 10px;
}

.siteMap label {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: lighter;
  /* line-height: 20px; */
  letter-spacing: -0.32px;
  font-size: 18px;
  margin-left: 10px;
}

.selectItems {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 10px 10px 10px 0px;
}

.selectItems div {
  margin-right: 10px;
}

.inputDisplay {
  display: flex;
}

.inputDisplay::before {
  display: none;
}

.selectOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 10px 10px 10px 0px;
}

.selectOptions div {
  margin: 20px 10px 20px 10px;
}

.sectionsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 0px 20px 0px;
}

.sections select {
  width: 50%;
}

.nextButtonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
}

.nextButton {
  border: none;
  border-radius: 10px;
  background-color: #002856;
  color: white;
  cursor: pointer;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabledNextButton {
  border: none;
  border-radius: 10px;
  background-color: lightgray;
  color: white;
  cursor: default;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButton {
  border: none;
  border-radius: 10px;
  background-color: red;
  color: white;
  cursor: pointer;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageSpecialtyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadImgSpecialty {
  display: none;
  border: none;
  background: teal;
}

.searchImage {
  color: #002856;
}

.imageHeaderSpecialty {
  width: 400px;
  height: auto;
}

.checkImage {
  color: teal;
}

.editContainer {
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 20px;
}

.nextEditButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.changeImg {
  border: none;
  border-radius: 10px;
  background-color: #002856;
  color: white;
  cursor: pointer;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.inputNameEdit {
  width: 100% !important;
}