.container__main {
    width: 100%;
    height: 224px;
    position: relative;
    overflow: hidden;
    /* background: linear-gradient(0deg, rgba(17, 88, 150, 0.5), rgba(17, 88, 150, 0.5)); */
  }
  
  .container__content {
    width: 1128px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 24px auto;
    z-index: 3;
  }

  .filter {
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(0deg, rgba(17, 88, 150, 0.5), rgba(17, 88, 150, 0.5)) !important;
    position: absolute !important;
  }
  
  .imagen__background {
    height: 100%;
    width: 100%;
    /* background-position: center center; */
    background-size:cover;
    /* background-attachment: fixed; */
    /* background-repeat: no-repeat; */
    z-index: 1;
    object-fit: cover;
position: absolute;
  }
  
  .container__frame1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  .container__frame1 img {
    width: 55px;
    height: 48px;
  }
  
  .container__frame2 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
  }
  
  .container__frame2 img {
    width: 71px;
    height: 62px;
  }
  
  .container__title_image {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: 0;
  }
  
  .container__title_image span {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.32px;
    font-family: Merriweather Sans;
    color: #fff;
    margin-bottom: 8px;
  }
  
  .container__title_image .titulo img {
    width: 36px;
    height: 36px;
    margin: 5px 11.5px 0 2px;
  }
  .titulo {
    display: flex;
  }
  .titleContain {
    /* text-transform: capitalize; */
    margin: 0;
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.32px;
    font-weight: 700;
    font-family: Merriweather;
    display: flex;
    align-items: flex-start;
    text-align: start;
  }
  
  /* Media query para tablet grande */
  @media screen and (max-width: 1175px) {
    .container__content {
      width: 100%;
    }
    .container__frame1 {
      margin-left: 24px;
    }
    .container__frame2 {
      right: 24px;
    }
  
    .container__title_image {
      width: 744px;
      right: 0;
      left: 0;
      margin: auto;
    }
  }
  
  /* Media query para tablet chica */
  @media screen and (max-width: 791px) {
    .container__frame2 {
      display: none;
    }
    .titleContain {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.82px;
    }
  
    .container__title_image .titulo img {
      width: 28px;
      height: 28px;
    }
  
    .container__title_image {
      width: 560px;
    }
  }
  
  /* Media query para movil */
  @media screen and (max-width: 607px) {
    .container__frame1 {
      display: none;
    }
    .container__title_image {
      width: 100%;
      padding: 0 24px;
      text-align: left;
      align-items: flex-start;
    }
  
    .container__title_image span {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.32px;
      font-family: Merriweather Sans;
      color: #fff;
      margin-bottom: 8px;
    }
  }
  