.containerGral {
  width: 100%;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.backofficeStatistics__container_main {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  padding: 20px;
  width: 99%;
}

.backofficeStatistics__container_title_addbutton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backofficeStatistics__container_button_add {
  width: 100px;
  background-color: white;
  border: green 2px solid;
  border-radius: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 200px;
  cursor: pointer;
}

.backofficeStatistics__container_button_quit {
  width: 100px;
  background-color: white;
  border-radius: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 200px;
  cursor: pointer;
}

.backofficeStatistics__container_button_save {
  width: 100px;
  background-color: white;
  border: none;
  border: green 2px solid;
  border-radius: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
}

.backofficeStatistics__container_fields {
  display: inline;
}

.backofficeStatistics__container_button_delete {
  display: inline;
  position: relative;
  top: 12px;
  right: 40px;
  cursor: pointer;
}

/* -------------------------------------------------------------------------------------------------------------------- */

.backofficeStatistics {
  width: 100%;
}

.backofficeNav {
  width: auto;
  margin: 10px 0 10px 0;
  position: sticky;
  top: 0;
  z-index: 999;
}

.sidebar-statistics {
  display: flex;
  flex-direction: row;
}

.sidebar {
  /* flex: 1; */
  width: auto;
  /* border: solid 3px yellow; */
}

.statisticsList {
  width: 100%;
  /* border: solid red 3px; */
}

.statisticsAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.inputStatitics {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statiticsForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.userAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  margin: 20px 0px 20px 20px;
}

.editButton {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.containerTable {
  /* margin-top: 20px; */
  height: 100%;
}

.customInputFile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
}

.customInputFile input {
  display: none;
}

.customInputFile span {
  font-size: 11px;
}

#searchButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #F1C40F;
}

.iconPictureImg {
  filter: brightness(0) saturate(100%) invert(11%) sepia(56%) saturate(2798%) hue-rotate(197deg) brightness(96%) contrast(103%);
  width: 35px;
}

.imgIcon {
  filter: brightness(0) saturate(100%) invert(11%) sepia(56%) saturate(2798%) hue-rotate(197deg) brightness(96%) contrast(103%);
  width: 40px;
}


