.title {
  font-size: 32px;
  font-weight: 700;
  font-family: Merriweather Sans;
  color: #002856;
  margin: 0px;
  height: 40px;
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdf5ea;
  width: 52px !important;
  height: 52px;
  border-radius: 50%;
}

.division {
  background-color: rgba(17, 88, 150, 0.15);
  height: 1px;
  margin: 8px 0 24px 0;
}

.description {
  font-size: 18px;
  font-weight: 700;
  font-family: Merriweather Sans;
  color: #002856;
  margin: 0px;
  height: 32px;
}

.subtitle {
  font-size: 24px;
  font-weight: 700;
  font-family: Merriweather Sans;
  color: #115896;
  height: 32px;
  margin: 0;
  margin-top: 24px;
}

.instalacion {
  display: flex;
  margin-top: 24px;
  justify-content: flex-start;
  align-items: center;
  width: 248px;
  height: 120px;
  padding: 16px 0 16px 8px;
}

.containerInfo {
  border-left: 1px solid rgba(17, 88, 150, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* width: 100%; */
  margin-left: 20px;
  padding-left: 20px;
}

.cant {
  font-size: 24px;
  font-weight: 700;
  font-family: Merriweather Sans;
  color: #002856;
  width: 38px;
  height: 32px;
  margin: 0px;
}

.name {
  color: #747678;
  font-weight: 400;
  font-size: 14px;
  font-family: Merriweather Sans;
  width: 140px;
  height: 18px;
  margin: 0px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 24px;
  column-gap: 184px;
  width: 100%;
}
.sections__button {
  display: none;
}
@media screen and (max-width: 1175px) {
  .sections__button {
    display: block;
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
  }
}
@media screen and (max-width: 791px) {
  .title {
    font-size: 24px;
    height: auto;
  }
  .sections__button {
    display: block;
    position: absolute;
    top: 3px;
    right: 0px;
    cursor: pointer;
  }

  .grid {
    column-gap: 32px;
  }
}
@media screen and (max-width: 607px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
