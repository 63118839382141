.container {
    width: 100%;
    height: 100%;
}

.selectContainer {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.containerButton {
    display: flex;
}

.selectContainer label {
    font-size: 18px;
}

.selectType {
    border: none;
    height: 30px;
    margin-left: 10px;
}

.newButton {
    border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #002856;
  color: white;
  cursor: pointer;
  margin-left: 40px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
    background-color: #002856;
}

.newInput {
    margin-left: 40px;
}