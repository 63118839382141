.father {
  background-color: rgb(255, 255, 255);
  width: 236px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
  transition: all 300ms linear;
  overflow-y: auto;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
}
.father::-webkit-scrollbar {
  -webkit-appearance: none;
}

.father::-webkit-scrollbar:vertical {
  width: 10px;
}

.father::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.father::-webkit-scrollbar:horizontal {
  height: 10px;
}

.father::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.father::-webkit-scrollbar-track {
  border-radius: 10px;
}
.header {
  background-color: #002856;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 100%;
  position: fixed;
  z-index: 5;
}
.titleMenu {
  color: #ffffff;
  font-family: Merriweather Sans;
  margin-left: 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.32px;
  margin-bottom: 0;
}
.close {
  color: #ffffff;
  border: none;
  background-color: transparent;
  width: auto;
  height: auto;
  margin-right: 25px;
  display: flex;
  align-items: center;
  z-index: 6;
  position: fixed;
  right: 0;
  transition: all 3ms ease;
}
.categories {
  margin-top: 64px;
}
.accordion-button {
  font-family: Merriweather Sans;
  padding: 16px 24px 0 24px !important;
}
.accordion-button.collapsed {
  font-size: 14px;
  font-weight: 700;
  color: #002856;
  margin: 0;
  transition: all 300ms linear;
}
.accordion-item {
  border-bottom: none !important;
}
.accordion-item h2 button {
  display: block;
  text-align: left;
  text-decoration: none;
}
.accordion-button:not(.collapsed) {
  font-size: 14px;
  font-weight: 700;
  color: #cd9700 !important;
  background-color: white !important;
  box-shadow: none !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) .arrow {
  filter: invert(56%) sepia(86%) saturate(625%) hue-rotate(9deg) brightness(89%)
    contrast(103%);
  transform: rotate(-180deg);
}
.accordion-body {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin-left: 10px;
  margin-right: 12px;
}
.cont {
  display: flex;
  text-align: left;
}
.titleOption {
  margin: 0;
  margin-top: 17px;
  color: #115896;
  font-weight: 700;
  margin-left: 5px;
  font-size: 10px;
  cursor: default;
  font-family: Merriweather Sans;
}
.titleOption2 {
  margin: 0;
  margin-top: 17px;
  color: #115896;
  font-weight: 700;
  margin-left: 5px;
  font-size: 10px;
  cursor: pointer;
  font-family: Merriweather Sans;
}
.logo {
  height: 16px;
  width: 16px;
  margin: auto 0;
  margin-top: 17px;
}
.division {
  margin: 6px 0 12px 0;
  height: 1px;
  width: 100%;
  background-color: #115896;
}
.contOpt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.opt {
  text-decoration: none;
  text-align: left;
  color: #575757;
  font-family: Merriweather Sans;
  font-size: 12px;
  font-weight: 400;
  margin: 6px 0;
  line-height: 15.08px;
}
.opt:hover {
  color: #115896;
}
.logo2 {
  height: 16px;
  width: 16px;
  margin: auto 0;
  margin-top: 17px;
  filter: brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(940%)
    hue-rotate(183deg) brightness(99%) contrast(96%);
}
.arrow {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  transition: all 300ms linear;
}
.containerBtn {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  align-items: center;
  margin-top: 16px;
}
.boton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 188px;
  height: 32px;
  font-size: 12px;
  font-family: Merriweather Sans;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.32px;
  background-color: white;
  border: 1px solid #002856;
  color: #002856;
  border-radius: 4px;
  margin: 8px 0;
  transition: all 300ms ease-in;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
}
.boton span {
  position: relative;
  z-index: 2;
  transition: all 0.2s ease;
}
.boton img {
  position: relative;
  z-index: 2;
  transition: all 0.2s ease;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 188px;
  height: 32px;
  font-size: 12px;
  font-family: Merriweather Sans;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.32px;
  background-color: #002856;
  border: 1px solid #002856;
  color: #ffffff;
  border-radius: 4px;
  margin: 10px 0 16px 0;
  transition: all 300ms ease-in;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
}
.button span {
  position: relative;
  z-index: 2;
  transition: all 0.2s ease;
}
.button img {
  position: relative;
  z-index: 2;
  transition: all 0.2s ease;
}
.btnLog {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}
.false {
  /* display: none; */
  right: -500px;
}
.none {
  display: none;
}
/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #02183141 radial-gradient(circle, transparent 1%, #ffffff 1%)
    center/15000%;
}
.ripple:active {
  background-color: #02183141;
  background-size: 100%;
  transition: background 0s;
}
.ripple2 {
  background-position: center;
  transition: background 0.8s;
}
.ripple2:hover {
  background: #021831 radial-gradient(circle, transparent 1%, #021831 1%)
    center/15000%;
}
.ripple2:active {
  background-color: #385e8a;
  background-size: 100%;
  transition: background 0s;
}
