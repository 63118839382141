.Header_container {
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.Header_in {
  width: 1128px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  position: relative;
}

.logoRF {
  width: 204.99px;
  height: 64px;
}

.Button_chat{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 186px;
  height: 40px;
  border-radius: 4px;
  font-family: Merriweather Sans;
  background-color:#e2b900;
  color: #fff;
  border: none;
  margin: 0px 0px 1px 24px;
  font-weight: 400;
}
.Button_chat img{
  margin-right: 8px;
}
.Button_chat:hover{
  background-color:#e7c940;
}
.Button_header {
  width: 186px;
  height: 40px;
  background-color: #115896;
  color: #fff;
  margin: 0px 0px 1px 24px;
  border-radius: 4px;
  border: none;
  font-family: Merriweather Sans;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 26px;
  padding: 7px 12px;
}

.Button_header:hover{
  background-color: #115896e7
}

.Header2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  justify-content: flex-end;
}

.headerResponsive {
  display: none;
}

/* ----------------------------------------------------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 1175px) {
  .Header_in {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 28px 0px 24px;
  }

  .Button_chat{
    display: none;
  }

  .Button_header {
    display: none;
  }

  .headerResponsive {
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: space-around;
    margin-left: 19px;
  }

  .headerResponsive button {
    width: 20px;
    height: 20px;
    border: none;
    background: #fff;
    display: flex;
    align-items: center;
  }

  .headerResponsive img {
    width: 18px;
    height: 18px;
  }
}
@media only screen and (max-width: 607px) {

  .Header_in {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 27px 0px 24px;
  }

  .Header_container {
    height: 64px;
    display: flex;
    align-items: center;
  }
  .headerResponsive {
    margin-left: 11px;
  }
  .headerResponsive img {
    width: 18px;
    height: 16px;
  }
  .logoRF {
    width: 140.93px;
    height: 44px;
    position: absolute;
    top: -1px;
  }
}
