.sectionContainer {
  width: 744px;
  padding: 40px;
  background: #ffffff;
  border-radius: 6px;
  grid-area: columnDer;
}

.container__busqueda_content {
  width: 100%;
  /* margin-top: 40px; */
  text-align: left;
}

.container__busqueda_content span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  color: #575757;
}

.container__enviaCV {
  width: 100%;
  height: 104px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.container__enviaCV h3 {
  font-family: Merriweather Sans;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.32px;
  font-weight: 700;
  color: #002856;
  margin-bottom: 16px;
}

.container__enviaCV span {
  font-weight: 400;
  font-family: Merriweather Sans;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #575757;
}

.container__enviaCV a {
  font-weight: 400;
  font-family: Merriweather Sans;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px;
  overflow: hidden;
}
.cont {
  width: 100%;
}
.title {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: -0.32px;
  color: #002856;
  width: max-content;
  text-align: start;
}
.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.division {
  height: 1px;
  width: 100%;
  background: rgba(17, 88, 150, 0.15);
  margin: 8px 0 24px 0;
}
.p {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17.6px !important;
  color: #575757;
  text-align: start;
  margin-left: 1px;
  width: 100%;
}
.p table {
  border: 1px;
}

.p table tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit !important;
  border-style: outset !important;
  border-width: 1px !important;
}

.img {
  display: none;
  cursor: pointer;
  margin-left: 10px;
}
.imgCircuitos {
  display: none;
  cursor: pointer;
  margin-left: 10px;
}
.reverse {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 1175px) {
  .img {
    display: flex;
  }
  .imgCircuitos {
    display: flex;
    align-self: flex-end;
  }
}
@media screen and (max-width: 791px) {
  .sectionContainer {
    width: 560px;
    padding: 40px 24px;
  }
  .title {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 607px) {
  .sectionContainer {
    width: 100vw;
    border-radius: 0;
  }
}
