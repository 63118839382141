.container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.imageContainer {
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding: 10px;
    width: 90%;
    height: 100%;
    margin: 20px 20px 0 20px;
}

.iconElement {
    color:#002856;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.inputImg {
    display: none;
}

.inputImgContainer {
    width: 100%;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inputImgContainer label {
    margin-right: 20px;
}

.imgContainerMap {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.imgContainerMap img {
    width: 180px;
    object-fit: cover;
}

.cancelIcon {
    position: absolute;
    top: 0;
    right: 0;
    color: red;
}