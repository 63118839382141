.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.table {
    width: 95%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.headerTable {
    display: flex;
    justify-content: space-around;
    padding-left: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.user {
    border: solid blue 1px;
    width: 20%;
}

.bodyTable {
    border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.trMap {
    border: solid blue 1px solid;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.tdMapUser {
    border: solid 1px red;
    width: 20%;
    display: flex;
    justify-content: center;
}

.emoticon {
    width: 40px !important;
    height: 40px !important;
    margin-right: 10px;
}

.containerDelete {
    width: 100%;
    height: 50px;
    display: flex;
    padding-left: 20px;
    justify-content: flex-start;
    align-items: center;
}

.deleteButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: red;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

.exportButton {
    padding: 6px 12px;
    margin: 10px 0;
    border: none;
    background-color: #FFF;
    color: #0056b3;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.exportButton:hover {
    background-color: #0056b3;
    color: #FFF;
}
