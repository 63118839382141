.buscador {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.conteiner {
  display: flex;
  align-items: center;
}
.link {
  text-decoration: none;
}
.searchBar {
  width: 296px !important;
  height: 40px;
  background: #e3e9ee !important;
  border-radius: 6px 0px 0px 6px !important;
  font-family: Merriweather Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  padding-left: 16px !important;
  border: none;
  outline: none;
}
.searchBar::placeholder {
  color: rgba(0, 0, 0, 0.288);
  font-weight: 600;
}

.searchBtn {
  width: 40px;
  height: 40px;
  border: none;
  background-color: #e2b900;
  border-radius: 0px 6px 6px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.searchBtn:hover{
  background-color:#e7c940;
}
.lupa {
  width: 16px;
  height: 16px;
  border: none;
  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2%)
    hue-rotate(32deg) brightness(107%) contrast(100%);
}
.sugerencias {
  width: 296px;
  box-shadow: 0px 2px 15px 4px #3ca2d120;
  background-color: white;
  position: absolute;
  z-index: 4;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  margin-top: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: Merriweather Sans;
  max-height: 235px;
  overflow-y: auto;
  overflow-x: hidden;
}
.sugerencias::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

.sugerencias::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

.sugerencias::-webkit-scrollbar-thumb {
  background-color: #79797986;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  /* border: 3px solid orange;  creates padding around scroll thumb */
}

.sugCont {
  width: 335px;
}


.option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: max-content;
  max-height: 40px !important;
  width: 296px;
  margin: 5px 0;
  color: #115896;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  padding-left: 16px;
  overflow: hidden;
}


.option:hover {
  color: #002856;
  font-weight: 600;
  background-color: rgba(20, 20, 95, 0.11);
}
.optionName {
  width: 276px;
  margin: 0;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.optionName::-webkit-scrollbar {
  display: none;
}

.ref {
  color: #aa7901;
  font-size: 12px;
  margin: 0;
  text-align: start;
  /* overflow: hidden; */
  height: 17.14px;
}
.falseSearch {
  display: none;
}
@media only screen and (max-width: 1175px) {
  .buscador {
    width: auto;
  }
}
@media only screen and (max-width: 608px) {
  .buscador {
    width: 30%;
    z-index: 3;
  }
  .buscador:hover{
    width: 100%;
  }
  .conteiner {
    width: 100%;
    justify-content: flex-end;
  }
  .searchBar {
    width: 0px !important;
    height: 40px;
    background: transparent !important;
    border-radius: 6px 0px 0px 6px !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    padding-left: 16px !important;
    padding-right: 0px !important;
    border: none;
    outline: none;
    transition: all 300ms ease-in;
  }
  .searchBtn {
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 6px 6px 0px;
    transition: all 300ms ease-in;
    background-color: transparent;
  }
  .lupa {
    filter: brightness(0) saturate(100%) invert(13%) sepia(22%) saturate(6280%)
      hue-rotate(195deg) brightness(92%) contrast(106%);
    width: 22px;
    height: 22px;
    transition: all 300ms ease-in;
  }
  .conteiner:hover .searchBtn {
    border-radius: 0px 6px 6px 0px;
    background-color: #e2b900;
    width: 40px !important;
    height: 40px !important;
  }
  .conteiner:hover .lupa {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(192deg)
      brightness(104%) contrast(101%);
    width: 16px !important;
    height: 16px !important;
  }
  .searchBar:focus ~ .searchBtn .lupa {
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2%)
      hue-rotate(32deg) brightness(107%) contrast(100%);
    width: 16px !important;
    height: 16px !important;
  }
  .conteiner:hover .searchBar {
    display: flex;
    width: 100% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 100% !important;
    height: 40px !important;
    background: #e3e9ee !important;
    margin: 0;
  }
  .searchBar:focus ~ .searchBtn {
    border-radius: 0px 6px 6px 0px;
    background-color: #e2b900;
    height: 40px !important;
    width: 40px !important;
  }
  .sugerencias {
    width: 75%;
    box-shadow: 0px 2px 15px 4px #3ca2d120;
    background-color: white;
    position: absolute;
    z-index: 4;
  }
  .sugCont {
    width: 100%;
  }
}
@media only screen and (max-width: 608px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 93% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 93% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 587px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 92% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 92% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 534px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 91% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 91% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 491px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 90% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 90% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 457px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 90% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 90% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 428px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 89% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 89% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 403px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 88% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 88% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 382px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 87% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 87% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 363px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 86% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 86% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 347px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 85% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 85% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 333px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 84% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 84% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
@media only screen and (max-width: 328px) {
  .searchBar {
    padding-left: 8px !important;
  }
}

@media only screen and (max-width: 321px) {
  .conteiner:hover .searchBar {
    display: flex;
    width: 83% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
  .searchBar:focus {
    display: flex;
    outline: none;
    width: 83% !important;
    height: 40px !important;
    background: #e3e9ee !important;
  }
}
