
.sidebar {
  width: 15%;
  height: auto;
  /* z-index: 990; */
  background-color: rgb(251, 251, 255);
  position: static;
  display: flex;
  justify-content: flex-start;
  bottom: 0px;
  background: #002856;
  margin: 0 15px 30px 0;
  overflow-y: scroll;
  /* padding-bottom: 20px; */
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
  height: 100% !important;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 2px 0px 2px 0px;
  color: white;
  font-family: Merriweather Sans;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  text-align: start;
}

.sidebarListItem:active,
.sidebarListItem:hover {
  background-color: #e2b900;
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}

.link {
  text-decoration: none;
  color: #555;
}

.link:hover {
  color: black;
}

#especialidad {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 15.5px;
  padding: 5px;
  border-radius: 10px;
  margin: 2px 0px 2px 0px;
  color: white;
  font-family: Merriweather Sans;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
}

#especialidad:active,
#especialidad:hover {
  background-color: #e2b900;
}

