.sedesContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  height: 64px;
}

.linkSedes {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.linkSedes img {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.linkSedes span {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.32px;
  color: #ffffff;
}

.linkPhone {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 210px;
  height: 40px;
  cursor: pointer;
}

.linkPhone img {
  height: 16px;
  width: 16px;
  position: relative;
  left: 6px;
}

.linkPhone a {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.32px;
  color: #ffffff;
  text-decoration: none;
}

.linkPhone a:hover {
  color: #ffffff;
}

.verticalLine {
  height: 24px;
  width: 1px;
  background-color: #fff;
  position: relative;
  left: 5px;
}
.linkSedes:hover .sedes {
  color: #e2b900;
}
.linkSedes:hover img {
  filter: brightness(0) saturate(100%) invert(59%) sepia(88%) saturate(1096%)
    hue-rotate(13deg) brightness(105%) contrast(101%);
}
.linkPhone:hover a {
  color: #e2b900;
}
.linkPhone:hover img {
  filter: brightness(0) saturate(100%) invert(59%) sepia(88%) saturate(1096%)
    hue-rotate(13deg) brightness(105%) contrast(101%);
}
