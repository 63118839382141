.containerRaiting {
  width: 272px;
  height: 116px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 0px;
  z-index: 150;
}

.articleContainer {
  width: 240px;
  height: 84px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.equis {
  filter: brightness(0) saturate(100%) invert(62%) sepia(10%) saturate(429%)
    hue-rotate(167deg) brightness(91%) contrast(89%);
  cursor: pointer;
}

.caritaContainer {
  width: 240px;
  height: 48px;
  display: flex;
  justify-content: space-between;
}

.cancelContainer {
  width: 100% !important;
  height: 20px !important;
  display: flex;
  justify-content: flex-end !important;
  margin: 0px !important;
  /* border: solid red 1px; */
  padding: 0px !important;
}

.containerRaitingOff {
  display: none;
}

.containerRaiting span {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #002856;
  margin-bottom: 16px;
}

.containerRaiting div {
  width: 240px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dissatisfiedClass {
  cursor: pointer;
  width: 48px !important;
  height: 48px !important;
  filter: brightness(0) saturate(100%) invert(12%) sepia(100%) saturate(7385%)
    hue-rotate(0deg) brightness(105%) contrast(103%);
}

.dissatisfiedOff {
  cursor: pointer;
  width: 48px !important;
  height: 48px !important;
  filter: brightness(0) saturate(100%) invert(80%) sepia(89%) saturate(294%)
    hue-rotate(184deg) brightness(95%) contrast(84%);
}

.satisfiedClass {
  cursor: pointer;
  width: 48px !important;
  height: 48px !important;
  filter: invert(54%) sepia(100%) saturate(370%) hue-rotate(71deg)
    brightness(97%) contrast(100%);
}

.probandoHover:hover ~ .probandoHover {
  filter: brightness(0) saturate(100%) invert(80%) sepia(89%) saturate(294%)
    hue-rotate(184deg) brightness(95%) contrast(84%);
}

.satisfiedOff {
  cursor: pointer;
  width: 48px !important;
  height: 48px !important;
  filter: brightness(0) saturate(100%) invert(80%) sepia(89%) saturate(294%)
    hue-rotate(184deg) brightness(95%) contrast(84%);
}

.normalClass {
  cursor: pointer;
  width: 48px !important;
  height: 48px !important;
  filter: brightness(0) saturate(100%) invert(71%) sepia(85%) saturate(478%)
    hue-rotate(359deg) brightness(97%) contrast(96%);
}

.normalOff {
  cursor: pointer;
  width: 48px !important;
  height: 48px !important;
  filter: brightness(0) saturate(100%) invert(80%) sepia(89%) saturate(294%)
    hue-rotate(184deg) brightness(95%) contrast(84%);
}

.observations {
  width: 272px;
  height: 314px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  border-radius: 6px;
  /* position: absolute;
  top: 130px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 0px;
  z-index: 4;
  -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.observationsOff {
  display: none;
  -webkit-animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: fade-out-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.formExperience {
  display: flex;
  flex-direction: column;
}

.formExperience textarea {
  width: 240px;
  height: 116px;
  background: rgba(17, 88, 150, 0.05);
  border-radius: 6px;
  border: none;
  padding: 8px;
  resize: none;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
  margin-top: 20px;
}

.spanContainer {
  width: 223px;
  /* height: 40px; */
  position: absolute;
  margin-top: 5px;
  margin-bottom: 12px;
  top: 16px;
}

.spanExperience {
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #002856;
}

.buttonExperience {
  position: absolute;
  width: 240px;
  height: 32px;
  left: 16px;
  bottom: 26px;
  border: none;
  background: #002856;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.32px;
  font-family: Merriweather Sans;
}

.imgClose {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 244px;
  top: 4px;
  cursor: pointer;
}

.icon,
.default-Icon {
  margin: 0 !important;
}

.inputMail {
  background: rgba(17, 88, 150, 0.05);
  width: 240px;
  height: 28px;
  padding: 8px;
  border-radius: 6px;
  margin: 10px 0 20px 0;
  border: none;
  outline: none;
  font-size: 12px;
}

@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 1175px) {
  .containerRaiting {
    width: 240px;
    height: 136px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    /* z-index: 90; */
    /* border: solid blue 1px; */
    /* padding: 0px 16px 16px 16px; */
  }

  .spanContainerRaiting {
    width: 169px !important;
    height: 48px;
    display: flex;
    justify-content: center;
  }

  .articleContainer {
    width: 208px;
    height: 104px;
    display: flex;
    align-items: center !important;
    flex-direction: column;
    justify-content: center;
  }

  .caritaContainer {
    width: 208px !important;
    height: 48px !important;
    display: flex;
    justify-content: space-between;
  }

  .cancelContainer {
    width: 100% !important;
    height: 10px !important;
    display: flex;
    justify-content: flex-end !important;
    margin: 0px !important;
    /* border: solid red 1px; */
    padding: 0px !important;
  }

  .equis {
    filter: brightness(0) saturate(100%) invert(62%) sepia(10%) saturate(429%)
      hue-rotate(167deg) brightness(91%) contrast(89%);
    cursor: pointer;
    /* position: absolute;
    top: 4px;
    left: 212px; */
  }

  .observations {
    width: 272px;
    height: 314px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
    border-radius: 6px;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 11;
    /* -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both; */
  }
}
