.itemContainer {
  width: 610px;
  height: 255px;
  display: flex;
  flex-direction: row;
  object-fit: cover;
  margin-left: 40px;
  margin-right: 0px;
  justify-content: flex-start;
}
.carousel-control-prev {
  width: 32px !important;
}
.carousel-control-next {
  width: 32px !important;
}
.carouselNewsImg {
  width: 360px;
  height: 240px;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  border-radius: 10.1759px;
  margin-left: 12px;
  padding: 0px;
}

.containerText{
 height: 106px;
 overflow: hidden;
 margin-bottom: 14px;
}

.carouselNewsTittle {
  width: 216px;
  height: max-content;
  max-height: 58px;
  font-family: Merriweather;
  font-style: normal;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 18px;
  color: #002856;
  text-align: start;
  margin-bottom: 12px;
  /* ...Puntos Suspensivos... */
  /* text-overflow: ellipsis;
  white-space: nowrap; */
  overflow: hidden;
}

.carouselNewsResume {
  width: 216px;
  max-height: 76px;
  font-family: Merriweather Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #002856;
  overflow: hidden;
  /* ...Puntos Suspensivos... */
  /* display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 43px;
  margin: 0 auto 24px;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.pContainer {
  position: absolute;
  width: 272px;
  height: 184px;
  top: 30px;
  left: 213px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border: 1px solid rgba(17, 88, 150, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(17, 88, 150, 0.5);
  border-radius: 6px;
  text-align: start;
  padding: 22px 24px;
  margin-left: 112px;
  /* white-space: nowrap; */
  /* overflow: hidden; */
}

.readNote {
  width: 216px;
  height: 20px;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.readNote p {
  font-family: Merriweather;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #002856;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.readNote img {
  width: 10px;
  height: 10px;
  padding: 0px;
}
@media only screen and (max-width: 1175px) {
  .itemContainer {
    width: 425px;
    align-items: center;
    margin-left: 0px;
    margin-right: 35px;
  }
  .carouselNewsImg {
    margin-left: 35px;
    width: 300px;
    height: 200px;
  }
  .pContainer {
    width: 208px;
    height: 154px;
    padding: 15px;
    margin-left: 0px;
    top: 50px;
  }
  .containerText{
    height: 80px;
    overflow: hidden;
   }
  .carouselNewsTittle {
    width: 176px;
    max-height: 54px;
    font-size: 18px;
    margin: 0;
    /* margin-bottom: 16px; */
  }
  .carouselNewsResume {
    width: 176px;
    max-height: 72px;
    margin-top: 7px;
    margin-bottom: 5px;
  }
  .readNote {
    width: 209px;
    margin-left: 32px;
  }
}
@media only screen and (max-width: 791px) {
  .itemContainer {
    width: 550px;
    height: 220px;
    margin-left: -30px;
    margin-right: 0px;
  }

  .carouselNewsImg {
    width: 300px;
    height: 200px;
    margin-left: 90px;
  }
  .pContainer {
    width: 208px;
    height: 154px;
    padding: 15px;
    top: 33px;
    margin-left: 50px;
  }
}
@media only screen and (max-width: 607px) {
  .itemContainer {
    flex-direction: column;
    height: 359px;
    width: 416px;
    align-items: center;
    margin-left: 10px;
    margin-right: 5px;
  }
  .carousel-control-prev {
    margin-left: 80px !important;
    margin-bottom: 210px !important;
  }
  .carousel-control-next {
    margin-right: 80px !important;
    margin-bottom: 210px !important;
  }
  .pContainer {
    width: 274px;
    height: 184px;
    top: 170px;
    left: 30px;
    padding: 22px 24px;
  }
  .carouselNewsImg {
    width: 272px;
    height: 182px;
    margin: 0;
  }
  .carouselNewsTittle {
    width: 100%;
    margin-bottom: 8px;
  }
  .carouselNewsResume {
    width: 100%;
    margin-bottom: 8px;
  }
  .readNote {
    width: 224px;
    margin-left: 0px;
    margin-top: 12px;
  }
}
