.carouselComponent {
  width: 1080px;
  height: 536px;
  object-fit:cover;
}

.carousel__container_main {
  width: 100%;
  height: 400px;
}

.carousel__container__form_url {
  background-color: RGBA(255, 255, 255, 0.5);
  width: 300px;
  height: 110px;
  margin: auto;
  color: #000;
  border-radius: 10px;
}

.carousel__container__form_url input {
  margin: auto;
}

.carousel__container__form_url input::placeholder {
  color: #000;
}

.carouse__container_button_url {
  width: 100px;
  border: none;
  background-color: transparent;
  border: green 2px solid;
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
  margin-top: 10px;
}

.noshow {
  display: none;
}

.image-carousel-container {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1175px) {
  .carousel__container_main {
    height: 334px;
  }
  .carouselComponent {
    height: 334px;
  }
}

@media only screen and (max-width: 791px) {
  .carousel__container_main {
    height: 264px;
  }
  .carouselComponent {
    height: 264px;
  }
}

@media only screen and (max-width: 607px) {
  .carousel__container_main {
    height: 360px;
  }
  .carouselComponent {
    height: 360px;
  }
}
