.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
}

.form {
  width: 90%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.name {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.nameLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.nameInput {
  width: 100% !important;
}

.mail {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.mailLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.mailInput {
  width: 100% !important;
}

.barrio {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin-top: 20px;
}

.barrioLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.barrioInput {
  width: 100% !important;
}

.domicilio {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin-top: 20px;
}

.domicilioLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.domicilioInput {
  width: 100% !important;
}

.tel {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin-top: 20px;
}

.telLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.telInput {
  width: 100% !important;
}

.about {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.aboutLabel {
  width: 100%;
  background: #002856;
  color: #fff;
}

.buttonContent {
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: #002856;
  color: #fff;
  margin: 10px;
}

.modal {
  position: fixed !important;
  z-index: 999 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  min-height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalButtonContainer {
  background: #fff;
  width: 100%;
  height: 100%;
}

.openModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: transparent;
  padding: 10px;
  border: none;
  /* margin: 20px;  */
}

.modalImageContainer {
  display: flex;
}

.modalImageContainerOff {
  display: none;
}

.modalContainer {
  width: 70%;
  height: 70% !important;
}

.containerButtonGroup {
  width: 70%;
  display: flex;
  justify-content: space-around;
  background-color: white;
  border-top: solid 1px lightgray;
}

.diasHorarios {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.diasHorariosLabel {
  color: white;
  background: #002856;
  width: 100%;
}

.diasHorariosInput {
  width: 100% !important;
}

.image {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.img {
    /* width: 60px; */
    height: 60px;
}

.deleteImg {
    color: red;
    margin-right: 10px;
}

.imageLabel {
    width: 100%;
    background: #002856;
    color:#fff;
}

.inputImg {
    display: none;
}

.imageContainer {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.galleryContainer {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.gallery {
    width: 100%;
    height: 200px;
    margin-top: 20px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.img {
    /* width: 60px; */
    height: 60px;
}

.deleteImg {
    color: red;
    margin-right: 10px;
}

.galleryLabel {
    width: 100%;
    background: #002856;
    color:#fff;
}

.galleryImg {
    display: none;
}

.iconContainer {
    margin-top: 10px;
    height: 30px;
}

.galleryItems {
    /* width: 80%; */
    height: 120px;
    padding: 5px;
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
}

.imgCont{
  margin: 10px;
}

.finishContainer {
    margin: 20px 0 50px 0;
    width: 100%;
}