.userList {
    width: 100%;
    /* border: solid red 3px; */
  }

  .userAddButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
  }

  .link-create {
      margin: 20px 0 20px 0;
      display: flex;
      justify-content: flex-start;
  }
  
  .nameListUser {
    display: flex;
    align-items: center;
  }
  
  .nameListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .userListEdit{
      border: none;
      border-radius: 10px;
      padding: 5px 10px;
      background-color: #3bb077;
      color: white;
      cursor: pointer;
      margin-right: 20px;
  }
  
  .delete-edit-button {
      width: 100%;
  }
  
  .userListDelete{
      color: red;
      cursor: pointer;
  }

  .sidebar {
      /* flex: 1; */
      width: auto;
      min-height: 100vh !important;
      overflow-y:visible;
      overflow-x: hidden;
  }

  /* ::-webkit-scrollbar {
      display: none;
    }
 */
  .backofficeNav {
      width: auto;
      margin: 10px 0 10px 0;
      /* position: absolute; */
      top: 0;
      z-index: 999;
  }

  .backofficeContainer {
      width: 100%;
  }

  .sidebarContainer {
      height: 100vh !important;
      display: flex;
      flex-direction: row;
  }

  .bodyContainer {
      width: 100%;
      height: 100vh !important;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 80px;
      display:block;
      overflow:auto;
      margin-bottom: 20px;
  }

  .bodyContainer::-webkit-scrollbar {display: none;}