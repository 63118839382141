.container {
    width: 100%;
    height: 100%;
}

.galleryButtonContainer {
    width: 100%;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
}

.button {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: #002856;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    margin-right: 15px;
}

.gridContainer {
    width: 90%;
    height: 100%;
    margin-top: 20px;
}