.container {
    width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.form {
    width: 1100px;
    height: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
  }

  .name {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }
  
  .nameLabel {
    color: white;
    background: #002856;
    width: 100%;
  }
  
  .nameInput {
    width: 100% !important;
  }

  .number {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    margin-top: 20px;
  }
  
  .numberLabel {
    color: white;
    background: #002856;
    width: 100%;
  }
  
  .numberInput {
    width: 100% !important;
  }

  .icon {
    width: 100%;
    height: 150px;
    max-height: 150px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .iconLabel {
    color: white;
    background: #002856;
    width: 100%;
  }
  
  .iconInput {
    width: 100% !important;
  }

  .inputContainer {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center !important;
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
  }

  .inputContainer input {
      display: none;
  }

  .iconElement {
      color:#002856;
      width: 40px;
      height: 40px;
      cursor: pointer;
  }

.buttonContainer {
    width: 100%;
    padding: 0 20px 0 0;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.createButton {
    width: 80px;
  border: none;
  padding: 5px;
  background-color: #002856;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.img {
    width: 40px;
}

.cancel {
  margin-top: 10px;
}

.x {
  color: red;
  cursor: pointer;
  width: 20px;
}